import axios from 'axios'
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import FormRekapPerformance from '../../Performance/Rekap'
import ChartHOK from '../../Performance/Rekap/DahsbordChart/HOK'
import ChartJOK from '../../Performance/Rekap/DahsbordChart/JOK'
import { Carousel } from 'react-bootstrap'
import ChartLate from '../../Performance/Rekap/DahsbordChart/Late'
import ChartAbsent from '../../Performance/Rekap/DahsbordChart/Absent'
import ChartSick from '../../Performance/Rekap/DahsbordChart/Sick'
import ChartPermission from '../../Performance/Rekap/DahsbordChart/Permission'
import ChartGoHome from '../../Performance/Rekap/DahsbordChart/GoHome'
import ChartDR from '../../Performance/Rekap/DahsbordChart/DR'
import ChartKPI from '../../Performance/Rekap/DahsbordChart/KPI'
import ChartEvaluation from '../../Performance/Rekap/DahsbordChart/Evaluation';
import { Loaders } from '../Utility';
import { jwtDecode } from 'jwt-decode'

const PerformanceDashboard = ({ dataEmployees, isAdmin, username }) => {

  const [dataPerformance, setDataPerformance] = useState([])
  console.log(dataPerformance)
  const [showFormRekap, setShowFormRekap] = useState(false)
  const [loading, setLoading] = useState(true);
  const formRekapRef = useRef(null)
  const navigate = useNavigate()

  const handleClickOutSide = (e) => {
    if (formRekapRef.current && !formRekapRef.current.contains(e.target)) {
      setShowFormRekap(false)
    }
  }

  useEffect(() => {
    setLoading(true);

    axios.get(`${process.env.REACT_APP_URL}/users/token`, { withCredentials: true })
      .then((res) => {
        const decoded = jwtDecode(res.data.token);
        const userStatus = decoded.status.toLowerCase();

        return axios.get(`${process.env.REACT_APP_URL}/performance`)
          .then((res) => {
            const allPerformance = res.data.dataPerformance;

            const filteredPerformance = userStatus === 'direktur ae'
              ? allPerformance.filter(emp => emp.unit.toLowerCase() === 'aditama energi')
              : userStatus === 'direktur bk'
                ? allPerformance.filter(emp => emp.unit.toLowerCase() === 'bara kumala')
                : userStatus === 'direktur sn'
                  ? allPerformance.filter(emp => emp.unit.toLowerCase() === 'sinet')
                  : userStatus === 'pm ae'
                    ? allPerformance.filter(emp => (emp.unit?.toLowerCase() === 'aditama energi' && emp.lokasi_kerja?.toLowerCase() === 'site' &&
                      ['produksi', 'umum', 'mine road', 'safety'].includes(emp.divisi?.toLowerCase())) ||
                      //aliyani
                      emp.nik === 2212063)
                    : userStatus === 'om ae'
                      ? allPerformance.filter(emp => emp.unit?.toLowerCase() === 'aditama energi' && emp.lokasi_kerja?.toLowerCase() === 'site' &&
                        ['engineering', 'explorasi', 'perencanaan'].includes(emp.divisi.toLowerCase()))
                      : userStatus === 'inspektur logistik'
                        ? allPerformance.filter(emp => (emp.divisi?.toLowerCase() === 'logistik') ||
                          //triyoga,aspian & wiwid
                          [2217314, 1112043, 2316232].includes(emp.nik))
                        : userStatus === 'sm bk'
                          ? allPerformance.filter(emp => emp.unit?.toLowerCase() === 'bara kumala' && emp.lokasi_kerja?.toLowerCase() === 'site')
                          : userStatus === 'koordinator sn'
                            ? allPerformance.filter(emp => emp.unit?.toLowerCase() === 'sinet' && emp.lokasi_kerja?.toLowerCase() === 'site')
                            : allPerformance;
            setDataPerformance(filteredPerformance);
          });
      })
      .catch((err) => {
        console.log('Error:', err.message);
        alert('Anda Belum login atau sesi anda habis, Login dulu ...!');
        window.location.replace('/login');
      })
      .finally(() => setLoading(false));

    document.addEventListener("mousedown", handleClickOutSide);
    return () => {
      document.removeEventListener("mousedown", handleClickOutSide);
    };
  }, []);

  return (
    <>
      {loading ? (
        <Loaders />
      ) : (
        <div className='container'>
          <div className='d-flex justify-content-between'>
            <div className='container title-h1 fs-1'>Kinerja Karyawan</div>
            <div className='title-h1 fs-1 me-3' style={{ cursor: 'pointer' }}
              onClick={() => setShowFormRekap(!showFormRekap)}
              data-tooltip-id='rekap-performance-tooltip' data-tooltip-content="Rekap performance"
            ><i className="bi bi-justify"></i></div>
            <Tooltip id="rekap-performance-tooltip" place='left' />
            {showFormRekap && (
              <FormRekapPerformance formRekapRef={formRekapRef} />
            )}
          </div>
          <div className='detail-employee'>
            {isAdmin && (
              <i className="bi bi-plus-lg fs-1"
                onClick={() => navigate('/inputPerformance', { state: { dataEmployees: dataEmployees, username: username } })}
                data-tooltip-id='input-performance-tooltip' data-tooltip-content="input performance"
              ></i>
            )}
            <Tooltip id='input-performance-tooltip' place='left' />
          </div>
          <Carousel className='mt-3' interval={2000} variant='dark'>
            <Carousel.Item>
              <ChartHOK dataPerformance={dataPerformance} />
            </Carousel.Item>
            <Carousel.Item>
              <ChartJOK dataPerformance={dataPerformance} />
            </Carousel.Item>
            <Carousel.Item>
              <ChartLate dataPerformance={dataPerformance} />
            </Carousel.Item>
            <Carousel.Item>
              <ChartAbsent dataPerformance={dataPerformance} />
            </Carousel.Item>
            <Carousel.Item>
              <ChartSick dataPerformance={dataPerformance} />
            </Carousel.Item>
            <Carousel.Item>
              <ChartPermission dataPerformance={dataPerformance} />
            </Carousel.Item>
            <Carousel.Item>
              <ChartGoHome dataPerformance={dataPerformance} />
            </Carousel.Item>
            <Carousel.Item>
              <ChartDR dataPerformance={dataPerformance} />
            </Carousel.Item>
            <Carousel.Item>
              <ChartKPI dataPerformance={dataPerformance} />
            </Carousel.Item>
            <Carousel.Item>
              <ChartEvaluation dataPerformance={dataPerformance} />
            </Carousel.Item>
          </Carousel>
        </div>
      )}
    </>
  )
}

export default PerformanceDashboard
