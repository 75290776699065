import React, { useRef } from 'react'
import { generateMonths } from '../../../Components/Utility/index';

const UpdateDailyReportCDL = ({ getMonthlyData, handleFormValue, year }) => {

  const formRef = useRef(null)
  const months = generateMonths(year)

  const dataPerformance = getMonthlyData('drCDL', months, year)
  const [dataJan,
    dataFeb,
    dataMar,
    dataApr,
    dataMei,
    dataJun,
    dataJul,
    dataAgust,
    dataSept,
    dataOkt,
    dataNov,
    dataDes,
  ] = dataPerformance

  const monthlyData = [
    { month: 'Jan', data: dataJan },
    { month: 'Feb', data: dataFeb },
    { month: 'Mar', data: dataMar },
    { month: 'Apr', data: dataApr },
    { month: 'Mei', data: dataMei },
    { month: 'Jun', data: dataJun },
    { month: 'Jul', data: dataJul },
    { month: 'Agust', data: dataAgust },
    { month: 'Sept', data: dataSept },
    { month: 'Okt', data: dataOkt },
    { month: 'Nov', data: dataNov },
    { month: 'Des', data: dataDes }
  ]

  return (
    <div className='container'>
      <form ref={formRef} className='d-flex justify-content-center'>
        <div className='p-2 bg-light m-2 rounded border border-dark d-flex flex-column'>
          <div className='title-h1 fs-5 text-center fs-5'>Daily Report</div>
          <div className='title-h1 fs-5 text-center fs-3'>CL + DL</div>
          {monthlyData.map(({ month, data }, i) => (
            <div key={i} className='d-flex gap-4 align-items-center border-bottom'>
              <label>{month}</label>
              <input
                type='text'
                className='input-underlined'
                name={`id${month}`}
                defaultValue={data.map(item => item.id)}
                style={{ display: 'none' }}
              />
              <input type='text' name='category' defaultValue='nilai DR' style={{ display: 'none' }} />
              <input
                type='decimal'
                className='input-underlined'
                name={`value${month}`}
                defaultValue={data.map(item => item.value)}
              />
              <button type='submit' className='btn fw-bold'
                onClick={(e) => handleFormValue(e, month, formRef)}
              >
                <i className="bi bi-check-lg"></i>
              </button>
            </div>
          ))}
        </div>
      </form>
    </div>
  )
}

export default UpdateDailyReportCDL