import React from 'react'
import { months } from '../../Components/Utility'
import { Tooltip } from 'react-tooltip'

const DailyReport = ({ filterDataPerformanceByNik, year }) => {

  const dataDrByMonthA = months.map((date) => {
    const data = filterDataPerformanceByNik('drA', date, year)
    if (data.length) {
      const value = parseFloat(data[0].value)
      return Number.isInteger(value) ? value : value.toFixed(2)
    }
    return '-';
  })

  const validValuesA = dataDrByMonthA.filter(value => value !== '-').map(value => parseFloat(value))
  const totalA = validValuesA.reduce((acc, value) => acc + value, 0)
  const averageA = (validValuesA.length > 0 ? (totalA / validValuesA.length) : 0).toFixed(2)

  const dataDrByMonthB = months.map((date) => {
    const data = filterDataPerformanceByNik('dr', date, year)
    if (data.length) {
      const value = parseFloat(data[0].value)
      return Number.isInteger(value) ? value : value.toFixed(2)
    }
    return '-';
  })

  const validValuesB = dataDrByMonthB.filter(value => value !== '-').map(value => parseFloat(value))
  const totalB = validValuesB.reduce((acc, value) => acc + value, 0)
  const averageB = (validValuesB.length > 0 ? (totalB / validValuesB.length) : 0).toFixed(2)

  const dataDrByMonthC = months.map((date) => {
    const data = filterDataPerformanceByNik('drC', date, year)
    if (data.length) {
      const value = parseFloat(data[0].value)
      return Number.isInteger(value) ? value : value.toFixed(2)
    }
    return '-';
  })

  const validValuesC = dataDrByMonthC.filter(value => value !== '-').map(value => parseFloat(value))
  const totalC = validValuesC.reduce((acc, value) => acc + value, 0)
  const averageC = (validValuesC.length > 0 ? (totalC / validValuesC.length) : 0).toFixed(2)

  const dataDrByMonthD = months.map((date) => {
    const data = filterDataPerformanceByNik('drD', date, year)
    if (data.length) {
      const value = parseFloat(data[0].value)
      return Number.isInteger(value) ? value : value.toFixed(2)
    }
    return '-';
  })

  const validValuesD = dataDrByMonthD.filter(value => value !== '-').map(value => parseFloat(value))
  const totalD = validValuesD.reduce((acc, value) => acc + value, 0)
  const averageD = (validValuesD.length > 0 ? (totalD / validValuesD.length) : 0).toFixed(2)

  const dataDrByMonthABL = months.map((date) => {
    const data = filterDataPerformanceByNik('drABL', date, year)
    if (data.length) {
      const value = parseFloat(data[0].value)
      return Number.isInteger(value) ? value : value.toFixed(2)
    }
    return '-';
  })

  const validValuesABL = dataDrByMonthABL.filter(value => value !== '-').map(value => parseFloat(value))
  const totalABL = validValuesABL.reduce((acc, value) => acc + value, 0)
  const averageABL = (validValuesABL.length > 0 ? (totalABL / validValuesABL.length) : 0).toFixed(2)

  const dataDrByMonthCDL = months.map((date) => {
    const data = filterDataPerformanceByNik('drCDL', date, year)
    if (data.length) {
      const value = parseFloat(data[0].value)
      return Number.isInteger(value) ? value : value.toFixed(2)
    }
    return '-';
  })

  const validValuesCDL = dataDrByMonthCDL.filter(value => value !== '-').map(value => parseFloat(value))
  const totalCDL = validValuesCDL.reduce((acc, value) => acc + value, 0)
  const averageCDL = (validValuesCDL.length > 0 ? (totalCDL / validValuesCDL.length) : 0).toFixed(2)

  return (
    <tbody className='table-info border-dark'>
      <tr className='text-center'>
        <td className='fw-bold' rowSpan={6} style={{ verticalAlign: "middle" }}>Daily Report</td>
        <td className='fw-bold'
          data-tooltip-id='tableA-tooltip'
          data-tooltip-content="Persiapan Kerja"
        >A</td>
        {dataDrByMonthA.map((value, i) => (
          <td key={i}>
            {value !== '-' ? parseFloat(value).toFixed(2) : value}
          </td>
        ))}
        <td style={{ fontWeight: 'bold' }}>{averageA > 0.00 ? `${averageA} %` : '-'}</td>
        <Tooltip id="tableA-tooltip" className='bg-dark text-light fw-bold' place='bottom' />
      </tr>
      <tr className='text-center'>
        <td className='fw-bold'
          data-tooltip-id='tableB-tooltip'
          data-tooltip-content="Pekerjaan Utama"
        >B</td>
        {dataDrByMonthB.map((value, i) => (
          <td key={i}>
            {value !== '-' ? parseFloat(value).toFixed(2) : value}
          </td>
        ))}
        <td style={{ fontWeight: 'bold' }}>{averageB > 0.00 ? `${averageB} %` : '-'}</td>
        <Tooltip id="tableB-tooltip" className='bg-dark text-light fw-bold' place='bottom' />
      </tr>
      <tr className='text-center'>
        <td className='fw-bold'
          data-tooltip-id='tableC-tooltip'
          data-tooltip-content="Support"
        >C</td>
        {dataDrByMonthC.map((value, i) => (
          <td key={i}>
            {value !== '-' ? parseFloat(value).toFixed(2) : value}
          </td>
        ))}
        <td style={{ fontWeight: 'bold' }}>{averageC > 0.00 ? `${averageC} %` : '-'}</td>
        <Tooltip id="tableC-tooltip" className='bg-dark text-light fw-bold' place='bottom' />
      </tr>
      <tr className='text-center'>
        <td className='fw-bold'
          data-tooltip-id='tableD-tooltip'
          data-tooltip-content="Standby/idle"
        >D</td>
        {dataDrByMonthD.map((value, i) => (
          <td key={i}>
            {value !== '-' ? parseFloat(value).toFixed(2) : value}
          </td>
        ))}
        <td style={{ fontWeight: 'bold' }}>{averageD > 0.00 ? `${averageD} %` : '-'}</td>
        <Tooltip id="tableD-tooltip" className='bg-dark text-light fw-bold' place='bottom' />
      </tr>
      <tr className='text-center table-primary border-dark'>
        <td className='fw-bold'
          data-tooltip-id='tableABL-tooltip'
          data-tooltip-content="Persipan & Pekerjaan Utama di Luar Jam Kerja"
        >AL & BL</td>
        {dataDrByMonthABL.map((value, i) => (
          <td key={i}>
            {value !== '-' ? parseFloat(value).toFixed(2) : value}
          </td>
        ))}
        <td style={{ fontWeight: 'bold' }}>{averageABL > 0.00 ? `${averageABL} %` : '-'}</td>
        <Tooltip id="tableABL-tooltip" className='bg-dark text-light fw-bold' place='bottom' />
      </tr>
      <tr className='text-center table-primary border-dark'>
        <td className='fw-bold'
          data-tooltip-id='tableCDL-tooltip'
          data-tooltip-content="Support & Standby di Luar Jam Kerja"
        >CL & DL</td>
        {dataDrByMonthCDL.map((value, i) => (
          <td key={i}>
            {value !== '-' ? parseFloat(value).toFixed(2) : value}
          </td>
        ))}
        <td style={{ fontWeight: 'bold' }}>{averageCDL > 0.00 ? `${averageCDL} %` : '-'}</td>
        <Tooltip id="tableCDL-tooltip" className='bg-dark text-light fw-bold' place='bottom' />
      </tr>
    </tbody >
  )
}

export default DailyReport