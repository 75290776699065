import React from 'react'
import { months } from '../../../Components/Utility'

const Absent = ({ filterDataPerformanceByNik, year }) => {

  const dataAbsentByMonth = months.map((date) => {
    const data = filterDataPerformanceByNik('mkr', date, year)
    if (data.length) {
      const value = parseFloat(data[0].value)
      return Number.isInteger(value) ? value : value.toFixed(2)
    }
    return '-';
  })

  const total = dataAbsentByMonth.reduce((acc, value) => {
    return value !== '-' ? acc + parseFloat(value) : acc
  }, 0)

  return (
    <tbody>
      <tr className='text-center table-secondary border-dark'>
        <td className='fw-bold' colSpan={2}>Mangkir</td>
        {dataAbsentByMonth.map((value, i) => (
          <td key={i} style={{
            color: value !== '-' && value >= 0 ? 'red' : 'black',
            fontWeight: value !== '-' && value >= 0 ? 'bold' : 'normal'
          }}>
            {value !== '-' ? `${value}` : value}</td>
        ))}
        <td style={{ fontWeight: 'bold' }}>{total !== 0 ? `${total} hari` : '-'}</td>
      </tr>
    </tbody>
  )
}

export default Absent
