import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import NavigationBar from '../../Components/Utility/Navbar';
import logoBK from '../../Assets/Logo/BK.png';
import { Tabs, Tab } from 'react-bootstrap';

const RekapVacationBK = ({ title }) => {
  const location = useLocation();
  const dataVacation = location.state.dataVacation;
  const dataEmployees = location.state.dataEmployees;

  const dataEmployee = dataEmployees.filter(item =>
    item.unit.toLowerCase() === 'bara kumala' &&
    item.status.toLowerCase() === 'aktif' &&
    (item.ikatan_kerja.toLowerCase() === 'kontrak' || item.ikatan_kerja.toLowerCase() === 'tetap')
  );

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agust', 'Sept', 'Okt', 'Nov', 'Des'];
  const dataLabel = ['No.', 'NIK', 'Nama', ...months, 'Total', 'Sisa'];

  const [vacationByYear, setVacationByYear] = useState({});
  const [yearList, setYearList] = useState([]);

  useEffect(() => {
    const groupedData = dataVacation.reduce((acc, vacation) => {
      const year = new Date(vacation.date_vacation).getFullYear();
      if (!acc[year]) acc[year] = [];
      acc[year].push(vacation);
      return acc;
    }, {});

    setVacationByYear(groupedData);
    setYearList(Object.keys(groupedData).sort((a, b) => b - a));
  }, [dataVacation]);

  const groupVacationByEmployeeAndMonth = (nik, month, year) => {
    const vacationData = vacationByYear[year]?.filter(vacation =>
      vacation.nik === nik &&
      new Date(vacation.date_vacation).getMonth() === month &&
      (vacation.category === 'cut' || vacation.category === 'cub')
    ) || [];
    return vacationData.reduce((total, vacation) => total + vacation.total, 0);
  };

  return (
    <>
      <NavigationBar logoBK={logoBK} title={title} />
      <div className='m-2'>
        {yearList.length > 0 ? (
          <Tabs defaultActiveKey={yearList[0]} className='mt-4'>
            {yearList.map(year => (
              <Tab eventKey={year} title={year} key={year}>
                <div className='mt-3'>
                  <table className='table table-bordered border-dark'>
                    <thead className='table-danger border-dark sticky-header'>
                      <tr>
                        {dataLabel.map((label, i) => (
                          <th key={i} className='text-center fw-bold fs-5'>{label}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {dataEmployee.filter(employee => {
                        const monthlyVacations = months.map((_, monthIndex) =>
                          groupVacationByEmployeeAndMonth(employee.nik, monthIndex, year)
                        );
                        const totalVacation = monthlyVacations.reduce((total, monthTotal) => total + monthTotal, 0);
                        return totalVacation > 0;
                      }).map((employee, j) => {
                        const monthlyVacations = months.map((_, monthIndex) =>
                          groupVacationByEmployeeAndMonth(employee.nik, monthIndex, year)
                        );
                        const totalVacation = monthlyVacations.reduce((total, monthTotal) => total + monthTotal, 0);

                        return (
                          <tr key={j} className='text-center'>
                            <td>{j + 1}</td>
                            <td>{employee.nik}</td>
                            <td className='text-start'>{employee.nama_lengkap}</td>
                            {monthlyVacations.map((total, k) => (
                              <td key={k}>{(total === 0 ? '-' : total)}</td>
                            ))}
                            <td>{(totalVacation === 0 ? '-' : totalVacation)}</td>
                            <td className='text-danger'>{12 - totalVacation} Hari</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Tab>
            ))}
          </Tabs>
        ) : (
          <div className='alert alert-danger text-center mt-4 text-dark fw-bold fs-1'>
            Belum ada yg cuti 😒
          </div>
        )}
      </div>
    </>
  );
};

export default RekapVacationBK;
