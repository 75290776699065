import React from 'react'

const DR = ({ dataEmployeeFiltered, dataPerformance }) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agust', 'Sept', 'Okt', 'Nov', 'Des']
  const dataLabel = ['No.', 'NIK', 'Nama', 'Type', ...months, 'Average']

  const groupPerformanceByEmployeeAndMonth = (nik, month, type) => {
    const filterTypes = type === 'Produktif' ? ['drA', 'dr'] : ['drC', 'drD'];

    const performanceData = dataPerformance.filter(
      (performance) =>
        performance.nik === nik &&
        new Date(performance.periode).getMonth() === month &&
        filterTypes.includes(performance.category)
    )
    return performanceData.length > 0
      ? performanceData.reduce((total, performance) => total + Number(performance.value), 0)
      : '-'
  }


  return (
    <table className="table table-bordered border-dark">
      <thead className="table-success table-bordered border-dark sticky-header">
        <tr>
          {dataLabel.map((label, i) => (
            <th key={i} className="text-center fw-bold">
              {label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {dataEmployeeFiltered.map((items, j) => {
          const produktifPerformance = months.map((_, monthIndex) =>
            groupPerformanceByEmployeeAndMonth(items.nik, monthIndex, 'Produktif')
          )

          const nonProduktifPerformance = months.map((_, monthIndex) =>
            groupPerformanceByEmployeeAndMonth(items.nik, monthIndex, 'Non Produktif')
          )

          const validProduktif = produktifPerformance.filter((value) => value !== '-');
          const averageProduktif =
            validProduktif.length > 0 ? (validProduktif.reduce((total, monthTotal) => total + monthTotal, 0) / validProduktif.length).toFixed(2)
              : '-'

          const validNonProduktif = nonProduktifPerformance.filter((value) => value !== '-');
          const averageNonProduktif =
            validNonProduktif.length > 0 ? (validNonProduktif.reduce((total, monthTotal) => total + monthTotal, 0) / validNonProduktif.length).toFixed(2)
              : '-'

          return (
            <React.Fragment key={j}>
              <tr className="text-center" style={{ verticalAlign: "middle" }}>
                <td rowSpan={2}>{j + 1}.</td>
                <td rowSpan={2}>{items.nik}</td>
                <td rowSpan={2} className="text-start">{items.nama_lengkap}</td>
                <td>Produktif</td>
                {produktifPerformance.map((total, k) => (
                  <td key={k}>{total !== '-' ? total : '-'}</td>
                ))}
                <td className="fw-bold">{averageProduktif !== '-' ? `${averageProduktif} %` : '-'}</td>
              </tr>
              <tr className='text-center table-secondary border-dark'>
                <td>Non Produktif</td>
                {nonProduktifPerformance.map((total, l) => (
                  <td key={l}>{total !== '-' ? total : '-'}</td>
                ))}
                <td className="fw-bold">{averageNonProduktif !== '-' ? `${averageNonProduktif} %` : '-'}</td>
              </tr>
            </React.Fragment>
          )
        })}
      </tbody>
    </table>
  )
}

export default DR
