import React, { useState } from 'react'
import NavigationBar from '../../../Components/Utility/Navbar'
import { useLocation } from 'react-router-dom'
import { getCurrentMonthYear } from '../../../Components/Utility'
import axios from 'axios'

const UpdateListKPI = ({ title }) => {

  const dateMonthFormated = (date) => {
    const isDate = new Date(date)
    const isDateFormated = `${isDate.toLocaleDateString('default', { month: "short" })} ${isDate.getFullYear()}`
    return isDateFormated
  }

  const [datePeriodeUpdateKPI, setDatePeriodeUpdateKPI] = useState(getCurrentMonthYear())
  const location = useLocation()
  const dataListKPI = location.state.dataListKPI;
  const username = location.state.username;
  const [searchName, setSearchName] = useState('')

  const filterDataListByPeriode = (periode, name) => {
    return dataListKPI.filter(list => {
      const dataPeriode = list.periode.slice(0, 7);
      const matchName = list.fullname.toLowerCase();
      return dataPeriode === periode && matchName.includes(name.toLowerCase());
    });
  };

  const filterPeriode = filterDataListByPeriode(datePeriodeUpdateKPI, searchName);

  const handleUpdateListKPI = (e) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const updateListKPI = Object.fromEntries(formData)

    const reqData = {
      id_listKPI: updateListKPI.id,
      new_periode: updateListKPI.periode,
      new_listKPI: updateListKPI.statusList,
      new_date: updateListKPI.date ? updateListKPI.date : null,
      new_updated_by: username
    }

    axios.put(`${process.env.REACT_APP_URL}/listkpi`, reqData)
      .then((res) => {
        if (res.data.updateListKPI) {
          alert(`Berhasil Update List KPI ${updateListKPI.fullname} periode ${dateMonthFormated(updateListKPI.periode)}`)
          window.location.replace('/dashboard')
        }
      })
      .catch((err) => {
        console.log(err.message)
        alert('gagal update list kpi')
      })
  }

  return (
    <>
      <NavigationBar
        title={title}
        setDatePeriodeUpdateKPI={setDatePeriodeUpdateKPI} />
      <div className='container p-4'>
        <input type='text' className='input-underlined w-25' placeholder='Cari Nama Karyawan....' onChange={(e) => setSearchName(e.target.value)} />
      </div>
      {filterPeriode
        .map((items, i) => {
          return (
            <form key={i} onSubmit={handleUpdateListKPI}
              className="container d-flex justify-content-center gap-4 p-2 bg-body-secondary border rounded mt-2 animate__animated animate__fadeInDown">
              <div className='d-none'>
                <label className='title-h1'>ID</label>
                <input type='number' name='id' className='input-underlined' value={items.id_listKPI} readOnly />
              </div>
              <div>
                <label className='title-h1'>NIK</label>
                <input type='number' name='nik' className='input-underlined' value={items.nik} readOnly />
              </div>
              <div>
                <label className='title-h1'>Nama</label>
                <input type='text' name='fullname' className='input-underlined' value={items.fullname} readOnly />
              </div>
              <div>
                <label className='title-h1'>Unit</label>
                <input type='text' name='unit' className='input-underlined' value={items.unit} readOnly />
              </div>
              <div className='d-none'>
                <label className='title-h1'>Periode</label>
                <input type='month' name='periode' className='input-underlined' value={items.periode.slice(0, 7)} readOnly />
              </div>
              <div>
                <label className='title-h1 text-danger'>Tanggal Ngumpul</label>
                <input type='date' name='date' className='input-underlined' defaultValue={items.date} />
              </div>
              <div>
                <label className='title-h1 text-danger'>Status List</label>
                <select name='statusList' className='input-underlined' defaultValue={items.listKPI}>
                  <option value="Sudah">Sudah</option>
                  <option value="Belum">Belum</option>
                </select>
              </div>
              <button type='submit' className='btn text-primary ms-5'>
                <i className='bi bi-check-lg fw-bold fs-2'></i>
              </button>
            </form>
          )
        })}
    </>
  )
}

export default UpdateListKPI