import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import NavigationBar from '../../../Components/Utility/Navbar';
import axios from 'axios';
import { getCurrentMonthYear } from '../../../Components/Utility';

const InputListKPI = ({ title }) => {

  const location = useLocation()
  const dataEmployees = location.state.dataEmployees;
  const username = location.state.username;
  const [selectedUnit, setSelectedUnit] = useState('')
  const [workLocation, setWorkLocation] = useState('')
  const [showStatus, setShowStatus] = useState(false)
  const [searchName, setSearchName] = useState('')
  const [datePeriodeListKPI, setDatePeriodeListKPI] = useState(getCurrentMonthYear())

  const filterEmployeesByCriteria = (unit, nama_lengkap, status, lokasi_kerja) => {
    return dataEmployees.filter(employee => {
      const matchUnit = unit ? employee.unit.toLowerCase() === unit.toLowerCase() : true
      const matchWorkLocation = lokasi_kerja ? employee.lokasi_kerja.toLowerCase() === lokasi_kerja.toLowerCase() : true
      const matchName = nama_lengkap ? employee.nama_lengkap.toLowerCase().includes(nama_lengkap.toLowerCase()) : true
      const matchStatus = status ? true : employee.status.toLowerCase() === 'aktif'
      return matchUnit && matchWorkLocation && matchName && matchStatus;
    })
  }

  const filteredEmployee = filterEmployeesByCriteria(selectedUnit, searchName, showStatus, workLocation)
  const [submittedNikPeriode, setSubmittedNikPeriode] = useState('')
  const [dataListKPI, setDataListKPI] = useState([])

  const filterDataListKPI = (nik, periode) => {
    return dataListKPI.filter(items => {
      const matchNik = nik ? items.nik === nik : true
      const matchPeriode = periode ? items.periode.toLowerCase() === periode.toLowerCase() : true
      return matchNik && matchPeriode;
    })
  }

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL}/listkpi`)
      .then((res) => {
        const nikPeriodeList = res.data.dataListKPI.map(item => `${item.nik}-${item.periode}`)
        setSubmittedNikPeriode(nikPeriodeList)
        setDataListKPI(res.data.dataListKPI)
      })
      .catch((err) => console.log(err.message))
  }, [])

  const handleFormListKPI = (e) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget);
    const formList = Object.fromEntries(formData);

    const reqData = {
      nik: formList.nik,
      fullname: formList.fullname,
      unit: formList.unit,
      periode: datePeriodeListKPI,
      listKPI: formList.listKPI,
      date: formList.date ? formList.date : null,
      updated_by: username,
    }

    axios.post(`${process.env.REACT_APP_URL}/listkpi`, reqData)
      .then((res) => {
        if (res.data.dataListKPI) {
          alert(`berhasil input list KPI ${formList.fullname}`)
          setSubmittedNikPeriode(prevState => [...prevState, `${formList.nik}-${datePeriodeListKPI}`])
        }
      })
      .catch((err) => {
        console.log(err.message)
        alert('gagal input list KPI coba lagi !!!')
      })
  }

  return (
    <>
      <NavigationBar setDatePeriodeListKPI={setDatePeriodeListKPI} title={title} />
      <div className='container bg-body-secondary d-flex flex-column mt-2 rounded animate__animated animate__fadeInDown'>
        <div className='d-flex justify-content-between mt-2'>
          <div className='d-flex flex-column w-25'>
            <div className='title-h1 fs-4'>Lokasi Kerja</div>
            <select className='form-select form-select-lg' onChange={(e) => setWorkLocation(e.target.value)}>
              <option value="">Semua</option>
              <option value="SMD">Samarinda</option>
              <option value="SITE">Site</option>
            </select>
          </div>
          <div className='d-flex flex-column mb-1 w-25'>
            <div className='title-h1 fs-4'>UNIT</div>
            <select className='form-select form-select-lg' onChange={(e) => setSelectedUnit(e.target.value)} >
              <option value="" >Semua Unit</option>
              <option value="ADITAMA ENERGI">ADITAMA ENERGI</option>
              <option value="BARA KUMALA">BARA KUMALA</option>
              <option value="SINET">SINET</option>
            </select>
          </div>
        </div>
        <hr />
        <div className='d-flex justify-content-between align-items-center mb-2'>
          <div className='form-check ' onChange={() => setShowStatus(!showStatus)}>
            <input type='checkbox' className='form-check-input' defaultChecked={showStatus} />
            <label className='form-check-label text-decoration-underline fw-bold text-danger'>NON AKTIF</label>
          </div>
          <input type='text' className='input-underlined w-25' placeholder='Cari Nama Karyawan....' onChange={(e) => setSearchName(e.target.value)} />
        </div>
        {filteredEmployee.map((emp, i) => {
          const nikPeriode = `${emp.nik}-${datePeriodeListKPI}`;
          const filterListKPI = filterDataListKPI(emp.nik, `${datePeriodeListKPI}-01`);
          const valueListKPI = filterListKPI.map(items => items.listKPI)
          const isValueListKPI = valueListKPI.length >= 1;

          return (
            <form key={i} className='bg-light rounded mt-2 shadow-sm p-2' onSubmit={handleFormListKPI}>
              <div className='d-flex gap-5 justify-content-center rounded align-items-center p-2'>
                <div className='d-flex flex-column p-2'>
                  <label className='title-h1'>NIK</label>
                  <input type='number' name='nik' className='input-underlined' value={emp.nik} readOnly />
                </div>
                <div className='d-flex flex-column p-2'>
                  <label className='title-h1'>Nama</label>
                  <input type='text' name='fullname' className='input-underlined' value={emp.nama_lengkap} readOnly />
                </div>
                <div className='d-flex flex-column p-2'>
                  <label className='title-h1'>Unit</label>
                  <input type='text' name='unit' className='input-underlined' value={emp.unit} readOnly />
                </div>
                <div className='d-flex flex-column p-2 d-none'>
                  <label className='title-h1'>Status</label>
                  <input type='text' name='status' className='input-underlined' value={emp.status} readOnly />
                </div>
                <div className='d-flex flex-column p-2'>
                  <label className='title-h1'>Lokasi Kerja</label>
                  <input type='text' name='workLocation' className='input-underlined' value={emp.lokasi_kerja} readOnly />
                </div>
                <div className='d-flex flex-column p-2' style={{ width: "200px" }}>
                  <label className='title-h1'>List KPI</label>
                  <select className='form-select' name='listKPI'>
                    <option value="Belum" selected>Belum</option>
                    <option value="Sudah">Sudah</option>
                  </select>
                </div>
                <div className='d-flex flex-column p-2'>
                  <label className='title-h1'>Tgl Ngumpul</label>
                  <input type='date' className='form-control' name='date' />
                </div>
                {!submittedNikPeriode.includes(nikPeriode) && !isValueListKPI && (
                  <button type='submit' className='btn text-primary mt-3'>
                    <i className='bi bi-check-lg fw-bold fs-2'></i>
                  </button>
                )}
              </div>
            </form>
          )
        })}
      </div>
    </>
  )
}

export default InputListKPI
