import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import UpdateKpi from './KPI/Update'
import UpdateHOK from './Absensi/HOK/Update'
import UpdateAbsent from './Absensi/Absent/Update'
import UpdateJOK from './Absensi/JOK/Update'
import UpdateLate from './Absensi/Late/Update'
import UpdateDailyReportB from './DR/Update/B'
import UpdatePermission from './Absensi/Permission/Update'
import UpdateSick from './Absensi/Sick/Update'
import UpdateGoHome from './Absensi/Go Home/Update'
import UpdateEvaluation from './Evaluation/Update'
import { Tab, Tabs } from 'react-bootstrap'
import UpdateDailyReportA from './DR/Update/A'
import UpdateDailyReportC from './DR/Update/C'
import UpdateDailyReportD from './DR/Update/D'
import UpdateDailyReportABL from './DR/Update/ABL'
import UpdateDailyReportCDL from './DR/Update/CDL'

const UpdatePerformance = ({ dataDetailEmployees }) => {
  const { nik } = dataDetailEmployees
  const navigate = useNavigate()
  const location = useLocation()
  const dataEmployees = location.state.dataEmployees
  const username = location.state.username;
  const [performanceByNikAndYear, setPerformanceByNikAndYear] = useState({})
  const [yearList, setYearList] = useState([])

  const filterDataPerformanceByNik = (category, periode, year) => {
    const dataForYear = performanceByNikAndYear[year] || [];
    return dataForYear.filter(items => {
      const matchCategory = category ? items.category.toLowerCase() === category.toLowerCase() : true
      const matchPeriode = periode ? items.periode.toLowerCase() === periode.toLowerCase() : true
      return matchCategory && matchPeriode
    })
  }

  const getMonthlyData = (category, dates, year) => {
    return dates.map(date => {
      const data = filterDataPerformanceByNik(category, date, year)
      return data.map(item => ({
        id: item.id_performance,
        category: item.category,
        value: item.value
      }))
    })
  }

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL}/performance/${nik}`)
      .then((res) => {
        const data = res.data.dataPerformanceByNik

        const performanceByYear = data.reduce((acc, item) => {
          const year = new Date(item.periode).getFullYear()
          if (!acc[year]) {
            acc[year] = []
          }
          acc[year].push(item)
          return acc
        }, {})

        setPerformanceByNikAndYear(performanceByYear);
        setYearList(Object.keys(performanceByYear).sort((a, b) => b - a))
      })
      .catch((err) => console.log('error', err))
  }, [nik])

  const handleFormValue = (e, month, formRef) => {
    e.preventDefault()

    if (formRef.current) {
      const formData = new FormData(formRef.current)
      const formValue = Object.fromEntries(formData.entries())

      const selectedId = formValue[`id${month}`]
      const selectedValue = formValue[`value${month}`]

      axios.put(`${process.env.REACT_APP_URL}/performance`, {
        id_performance: selectedId,
        new_value: selectedValue,
        new_updated_by: username
      })
        .then((res) => {
          console.log(res.data.performance)
          if (res.data.performance) {
            alert(`Berhasil mengubah ${formValue.category}`)
            navigate(`/detailEmployee/${nik}`, { state: { dataEmployees: dataEmployees } })
          }
        }).catch((err) => {
          console.log(err.response)
          console.log(err.message)
          alert(`${formValue.category} undefined`)
        })
    } else {
      console.log('form undefined')
    }
  }

  return (
    <div className='d-flex flex-column'>
      <Tabs defaultActiveKey={yearList[0]}>
        {yearList.map((year) => (
          <Tab eventKey={year} title={year} key={year}>
            <div className='d-flex'>
              <UpdateHOK year={year} getMonthlyData={getMonthlyData} handleFormValue={handleFormValue} />
              <UpdateJOK year={year} getMonthlyData={getMonthlyData} handleFormValue={handleFormValue} />
              <UpdateKpi year={year} getMonthlyData={getMonthlyData} handleFormValue={handleFormValue} />
              <UpdateEvaluation year={year} getMonthlyData={getMonthlyData} handleFormValue={handleFormValue} />
            </div>
            <div className='d-flex'>
              <UpdateDailyReportA year={year} getMonthlyData={getMonthlyData} handleFormValue={handleFormValue} />
              <UpdateDailyReportB year={year} getMonthlyData={getMonthlyData} handleFormValue={handleFormValue} />
              <UpdateDailyReportC year={year} getMonthlyData={getMonthlyData} handleFormValue={handleFormValue} />
              <UpdateDailyReportD year={year} getMonthlyData={getMonthlyData} handleFormValue={handleFormValue} />
              <UpdateDailyReportABL year={year} getMonthlyData={getMonthlyData} handleFormValue={handleFormValue} />
              <UpdateDailyReportCDL year={year} getMonthlyData={getMonthlyData} handleFormValue={handleFormValue} />

            </div>
            <div className='d-flex'>
              <UpdateLate year={year} getMonthlyData={getMonthlyData} handleFormValue={handleFormValue} />
              <UpdateAbsent year={year} getMonthlyData={getMonthlyData} handleFormValue={handleFormValue} />
              <UpdatePermission year={year} getMonthlyData={getMonthlyData} handleFormValue={handleFormValue} />
              <UpdateSick year={year} getMonthlyData={getMonthlyData} handleFormValue={handleFormValue} />
              <UpdateGoHome year={year} getMonthlyData={getMonthlyData} handleFormValue={handleFormValue} />
            </div>
          </Tab>
        ))}
      </Tabs>
    </div>
  )
}

export default UpdatePerformance
