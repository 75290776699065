import React, { useState } from 'react'
import { FloatingLabel, Tab, Tabs } from 'react-bootstrap'
import { calculateDuration, changeDurationMonth, isValueEmpty } from '../Components/Utility'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'

const UpdatePkwt = ({ dataDetailEmployees }) => {

  const navigate = useNavigate('')
  const location = useLocation()
  const dataEmployees = location.state.dataEmployees

  const { nik,
    no_pkwt_1, jabatan_pkwt_1, awal_pkwt_1, akhir_pkwt_1, no_pPkwt_1, akhir_pPkwt_1, catatan_pkwt_1,
    no_pkwt_2, jabatan_pkwt_2, awal_pkwt_2, akhir_pkwt_2, no_pPkwt_2, akhir_pPkwt_2, catatan_pkwt_2,
    no_pkwt_3, jabatan_pkwt_3, awal_pkwt_3, akhir_pkwt_3, no_pPkwt_3, akhir_pPkwt_3, catatan_pkwt_3,
    no_pkwt_4, jabatan_pkwt_4, awal_pkwt_4, akhir_pkwt_4, catatan_pkwt_4,
    no_pkwt_5, jabatan_pkwt_5, awal_pkwt_5, akhir_pkwt_5, catatan_pkwt_5,
    no_pkwt_6, jabatan_pkwt_6, awal_pkwt_6, akhir_pkwt_6, catatan_pkwt_6,
    no_pkwt_7, jabatan_pkwt_7, awal_pkwt_7, akhir_pkwt_7, catatan_pkwt_7,
    no_pkwt_8, jabatan_pkwt_8, awal_pkwt_8, akhir_pkwt_8, catatan_pkwt_8,
    no_pkwt_9, jabatan_pkwt_9, awal_pkwt_9, akhir_pkwt_9, catatan_pkwt_9,
    no_pkwt_10, jabatan_pkwt_10, awal_pkwt_10, akhir_pkwt_10, catatan_pkwt_10,
    no_pkwt_11, jabatan_pkwt_11, awal_pkwt_11, akhir_pkwt_11, catatan_pkwt_11,
    no_pkwt_12, jabatan_pkwt_12, awal_pkwt_12, akhir_pkwt_12, catatan_pkwt_12,
    riwayat_jabatan
  } = dataDetailEmployees

  const [dateStartPkwt1, setDateStartPkwt1] = useState(awal_pkwt_1)
  const [dateEndPkwt1, setDateEndPkwt1] = useState(akhir_pkwt_1)
  const [datePpkwt1, setDatePpkwt1] = useState(akhir_pPkwt_1)
  const [dateStartPkwt2, setDateStartPkwt2] = useState(awal_pkwt_2)
  const [dateEndPkwt2, setDateEndPkwt2] = useState(akhir_pkwt_2)
  const [datePpkwt2, setDatePpkwt2] = useState(akhir_pPkwt_2)
  const [dateStartPkwt3, setDateStartPkwt3] = useState(awal_pkwt_3)
  const [dateEndPkwt3, setDateEndPkwt3] = useState(akhir_pkwt_3)
  const [datePpkwt3, setDatePpkwt3] = useState(akhir_pPkwt_3)
  const [dateStartPkwt4, setDateStartPkwt4] = useState(awal_pkwt_4)
  const [dateEndPkwt4, setDateEndPkwt4] = useState(akhir_pkwt_4)
  const [dateStartPkwt5, setDateStartPkwt5] = useState(awal_pkwt_5)
  const [dateEndPkwt5, setDateEndPkwt5] = useState(akhir_pkwt_5)
  const [dateStartPkwt6, setDateStartPkwt6] = useState(awal_pkwt_6)
  const [dateEndPkwt6, setDateEndPkwt6] = useState(akhir_pkwt_6)
  const [dateStartPkwt7, setDateStartPkwt7] = useState(awal_pkwt_7)
  const [dateEndPkwt7, setDateEndPkwt7] = useState(akhir_pkwt_7)
  const [dateStartPkwt8, setDateStartPkwt8] = useState(awal_pkwt_8)
  const [dateEndPkwt8, setDateEndPkwt8] = useState(akhir_pkwt_8)
  const [dateStartPkwt9, setDateStartPkwt9] = useState(awal_pkwt_9)
  const [dateEndPkwt9, setDateEndPkwt9] = useState(akhir_pkwt_9)
  const [dateStartPkwt10, setDateStartPkwt10] = useState(awal_pkwt_10)
  const [dateEndPkwt10, setDateEndPkwt10] = useState(akhir_pkwt_10)
  const [dateStartPkwt11, setDateStartPkwt11] = useState(awal_pkwt_11)
  const [dateEndPkwt11, setDateEndPkwt11] = useState(akhir_pkwt_11)
  const [dateStartPkwt12, setDateStartPkwt12] = useState(awal_pkwt_12)
  const [dateEndPkwt12, setDateEndPkwt12] = useState(akhir_pkwt_12)

  const datePairs = [
    { start: dateStartPkwt1, end: dateEndPkwt1 },
    { start: dateStartPkwt1, end: datePpkwt1 },
    { start: dateStartPkwt2, end: dateEndPkwt2 },
    { start: dateStartPkwt2, end: datePpkwt2 },
    { start: dateStartPkwt3, end: dateEndPkwt3 },
    { start: dateStartPkwt3, end: datePpkwt3 },
    { start: dateStartPkwt4, end: dateEndPkwt4 },
    { start: dateStartPkwt5, end: dateEndPkwt5 },
    { start: dateStartPkwt6, end: dateEndPkwt6 },
    { start: dateStartPkwt7, end: dateEndPkwt7 },
    { start: dateStartPkwt8, end: dateEndPkwt8 },
    { start: dateStartPkwt9, end: dateEndPkwt9 },
    { start: dateStartPkwt10, end: dateEndPkwt10 },
    { start: dateStartPkwt11, end: dateEndPkwt11 },
    { start: dateStartPkwt12, end: dateEndPkwt12 }
  ]

  const formatedDurations = datePairs.map(pair => calculateDuration(pair.start, pair.end, changeDurationMonth))

  const [
    durationPkwt1Formated, durationPpkwt1Formated,
    durationPkwt2Formated, durationPpkwt2Formated,
    durationPkwt3Formated, durationPpkwt3Formated,
    durationPkwt4Formated, durationPkwt5Formated, durationPkwt6Formated, durationPkwt7Formated,
    durationPkwt8Formated, durationPkwt9Formated, durationPkwt10Formated, durationPkwt11Formated, durationPkwt12Formated
  ] = formatedDurations

  const handleFormPkwt = (e) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const formPkwt = Object.fromEntries(formData)

    const reqDataPkwt = {
      nik: nik,
      new_no_pkwt_1: formPkwt.noPkwt1,
      new_jabatan_pkwt_1: formPkwt.positionPkwt1,
      new_awal_pkwt_1: formPkwt.dateStartPkwt1,
      new_akhir_pkwt_1: formPkwt.dateEndPkwt1,
      new_no_pPkwt_1: formPkwt.noPpkwt1,
      ...(formPkwt.dateEndPkwt1 && {
        new_akhir_pPkwt_1: formPkwt.datePpkwt1 || null
      }),
      new_catatan_pkwt_1: formPkwt.notesPkwt1,
      new_no_pkwt_2: formPkwt.noPkwt2,
      new_jabatan_pkwt_2: formPkwt.positionPkwt2,
      new_awal_pkwt_2: formPkwt.dateStartPkwt2,
      new_akhir_pkwt_2: formPkwt.dateEndPkwt2,
      new_no_pPkwt_2: formPkwt.noPpkwt2,
      ...(formPkwt.dateEndPkwt2 && {
        new_akhir_pPkwt_2: formPkwt.datePpkwt2 || null
      }),
      new_catatan_pkwt_2: formPkwt.notesPkwt2,
      new_no_pkwt_3: formPkwt.noPkwt3,
      new_jabatan_pkwt_3: formPkwt.positionPkwt3,
      new_awal_pkwt_3: formPkwt.dateStartPkwt3,
      new_akhir_pkwt_3: formPkwt.dateEndPkwt3,
      new_no_pPkwt_3: formPkwt.noPpkwt3,
      ...(formPkwt.dateEndPkwt3 && {
        new_akhir_pPkwt_3: formPkwt.datePpkwt3 || null
      }),
      new_catatan_pkwt_3: formPkwt.notesPkwt3,
      new_no_pkwt_4: formPkwt.noPkwt4,
      new_jabatan_pkwt_4: formPkwt.positionPkwt4,
      new_awal_pkwt_4: formPkwt.dateStartPkwt4,
      new_akhir_pkwt_4: formPkwt.dateEndPkwt4,
      new_catatan_pkwt_4: formPkwt.notesPkwt4,
      new_no_pkwt_5: formPkwt.noPkwt5,
      new_jabatan_pkwt_5: formPkwt.positionPkwt5,
      new_awal_pkwt_5: formPkwt.dateStartPkwt5,
      new_akhir_pkwt_5: formPkwt.dateEndPkwt5,
      new_catatan_pkwt_5: formPkwt.notesPkwt5,
      new_no_pkwt_6: formPkwt.noPkwt6,
      new_jabatan_pkwt_6: formPkwt.positionPkwt6,
      new_awal_pkwt_6: formPkwt.dateStartPkwt6,
      new_akhir_pkwt_6: formPkwt.dateEndPkwt6,
      new_catatan_pkwt_6: formPkwt.notesPkwt6,
      new_no_pkwt_7: formPkwt.noPkwt7,
      new_jabatan_pkwt_7: formPkwt.positionPkwt7,
      new_awal_pkwt_7: formPkwt.dateStartPkwt7,
      new_akhir_pkwt_7: formPkwt.dateEndPkwt7,
      new_catatan_pkwt_7: formPkwt.notesPkwt7,
      new_no_pkwt_8: formPkwt.noPkwt8,
      new_jabatan_pkwt_8: formPkwt.positionPkwt8,
      new_awal_pkwt_8: formPkwt.dateStartPkwt8,
      new_akhir_pkwt_8: formPkwt.dateEndPkwt8,
      new_catatan_pkwt_8: formPkwt.notesPkwt8,
      new_no_pkwt_9: formPkwt.noPkwt9,
      new_jabatan_pkwt_9: formPkwt.positionPkwt9,
      new_awal_pkwt_9: formPkwt.dateStartPkwt9,
      new_akhir_pkwt_9: formPkwt.dateEndPkwt9,
      new_catatan_pkwt_9: formPkwt.notesPkwt9,
      new_no_pkwt_10: formPkwt.noPkwt10,
      new_jabatan_pkwt_10: formPkwt.positionPkwt10,
      new_awal_pkwt_10: formPkwt.dateStartPkwt10,
      new_akhir_pkwt_10: formPkwt.dateEndPkwt10,
      new_catatan_pkwt_10: formPkwt.notesPkwt10,
      new_no_pkwt_11: formPkwt.noPkwt11,
      new_jabatan_pkwt_11: formPkwt.positionPkwt11,
      new_awal_pkwt_11: formPkwt.dateStartPkwt11,
      new_akhir_pkwt_11: formPkwt.dateEndPkwt11,
      new_catatan_pkwt_11: formPkwt.notesPkwt11,
      new_no_pkwt_12: formPkwt.noPkwt12,
      new_jabatan_pkwt_12: formPkwt.positionPkwt12,
      new_awal_pkwt_12: formPkwt.dateStartPkwt12,
      new_akhir_pkwt_12: formPkwt.dateEndPkwt12,
      new_catatan_pkwt_12: formPkwt.notesPkwt12,
      new_riwayat_jabatan: formPkwt.historyPosition,
    }

    axios.put(`${process.env.REACT_APP_URL}/employees/pkwt`, reqDataPkwt)
      .then((res) => {
        if (res.data.pkwtEmployee) {
          alert("Berhasil Update PKWT Karyawan")
          navigate(`/detailEmployee/${nik}`, { state: { dataEmployees: dataEmployees } })
        }
      }).catch((err) => {
        console.log(err)
        alert('Gagal Update PKWT karyawan')
      })
  }

  return (
    <>
      <div className='container mt-1 border rounded p-3 bg-body-secondary'>
        <Tabs>
          <Tab eventKey="contract_1" title="KONTRAK-1">
            <form className='p-2 bg-light mt-1 rounded d-flex flex-column gap-1 was-validated' onSubmit={handleFormPkwt}>
              <div className='d-flex gap-2 '>
                <div className='d-flex flex-column w-50 '>
                  <label className='ms-2 fw-bold fs-5'>No. PKWT</label>
                  <FloatingLabel label='Masukan No PKWT'>
                    <input type='text' placeholder='Masukan No. PKWT' className='form-control' name='noPkwt1' defaultValue={no_pkwt_1} required />
                  </FloatingLabel>
                  <label className='ms-2 fw-bold fs-5'>Jabatan</label>
                  <FloatingLabel label='Masukan Jabatan'>
                    <input type='text' placeholder='Masukan Jabatan' className='form-control' name='positionPkwt1' defaultValue={jabatan_pkwt_1} required />
                  </FloatingLabel>
                  <div className='d-flex gap-2'>
                    <div className='d-flex flex-column w-50'>
                      <label className='ms-2 fw-bold fs-5'>Awal PKWT</label>
                      <FloatingLabel label='Masukan awal PKWT'>
                        <input type='date' placeholder='Masukan tgl awal PKWT' className='form-control' name='dateStartPkwt1' defaultValue={awal_pkwt_1} onChange={(e) => setDateStartPkwt1(e.target.value)} required />
                      </FloatingLabel>
                    </div>
                    <div className='d-flex flex-column w-50'>
                      <label className='ms-2 fw-bold fs-5'>Akhir PKWT</label>
                      <FloatingLabel label='Masukan akhir PKWT'>
                        <input type='date' placeholder='Masukan tgl akhir PKWT' className='form-control' name='dateEndPkwt1' defaultValue={akhir_pkwt_1} onChange={(e) => setDateEndPkwt1(e.target.value)} />
                      </FloatingLabel>
                    </div>
                  </div>
                  <label className='fs-5 ms-2' >Durasi : {durationPkwt1Formated} Bulan</label>
                </div>
                <div className='d-flex flex-column w-50'>
                  <label className='ms-2 fw-bold fs-5'>No. P-PKWT</label>
                  <FloatingLabel label='Masukan No P-PKWT'>
                    <input type='text' placeholder='Masukan No P-PKWT' className='form-control' name='noPpkwt1' defaultValue={no_pPkwt_1} />
                  </FloatingLabel>
                  <div className='d-flex'>
                    <div className='d-flex flex-column w-50'>
                      <label className='ms-2 fw-bold fs-5'>Tanggal P-PKWT</label>
                      <FloatingLabel label='Masukan tgl P-PKWT'>
                        <input type='date' placeholder='Masukan tgl P-PKWT' className='form-control' name='datePpkwt1' defaultValue={akhir_pPkwt_1} onChange={(e) => setDatePpkwt1(e.target.value)} />
                      </FloatingLabel>
                    </div>
                    <label className='ms-4 mt-4 fs-5 d-flex justify-content-center align-items-center'>Durasi : {durationPpkwt1Formated} Bulan</label>
                  </div>
                  <label className='ms-2 fw-bold fs-5'>Catatan</label>
                  <FloatingLabel label='Masukan catatan jika ada'>
                    <textarea type='text' placeholder='Masukan catatan jika ada' className='form-control' name='notesPkwt1' defaultValue={catatan_pkwt_1} style={{ height: "90px" }} />
                  </FloatingLabel>
                </div>
                <div className='d-flex justify-content-center align-items-center'>
                  <button type='submit' className='btn btn-lg fs-2 h-25'>
                    <i className='bi bi-check-lg text-dark fw-bold border border-2 shadow shadow-sm p-2 rounded'></i>
                  </button>
                </div>
              </div>
            </form>
          </Tab>
          {!isValueEmpty(no_pkwt_1) && (
            <Tab eventKey="contract_2" title="KONTRAK-2">
              <form className='p-2 bg-light mt-1 rounded d-flex flex-column gap-1 was-validated' onSubmit={handleFormPkwt}>
                <div className='d-flex gap-2 '>
                  <div className='d-flex flex-column w-50 '>
                    <label className='ms-2 fw-bold fs-5'>No. PKWT</label>
                    <FloatingLabel label='Masukan No PKWT'>
                      <input type='text' placeholder='Masukan No. PKWT' className='form-control' name='noPkwt2' defaultValue={no_pkwt_2} required />
                    </FloatingLabel>
                    <label className='ms-2 fw-bold fs-5'>Jabatan</label>
                    <FloatingLabel label='Masukan Jabatan'>
                      <input type='text' placeholder='Masukan Jabatan' className='form-control' name='positionPkwt2' defaultValue={jabatan_pkwt_2} required />
                    </FloatingLabel>
                    <div className='d-flex gap-2'>
                      <div className='d-flex flex-column w-50'>
                        <label className='ms-2 fw-bold fs-5'>Awal PKWT</label>
                        <FloatingLabel label='Masukan awal PKWT'>
                          <input type='date' placeholder='Masukan tgl awal PKWT' className='form-control' name='dateStartPkwt2' defaultValue={awal_pkwt_2} onChange={(e) => setDateStartPkwt2(e.target.value)} required />
                        </FloatingLabel>
                      </div>
                      <div className='d-flex flex-column w-50'>
                        <label className='ms-2 fw-bold fs-5'>Akhir PKWT</label>
                        <FloatingLabel label='Masukan akhir PKWT'>
                          <input type='date' placeholder='Masukan tgl akhir PKWT' className='form-control' name='dateEndPkwt2' defaultValue={akhir_pkwt_2} onChange={(e) => setDateEndPkwt2(e.target.value)} required />
                        </FloatingLabel>
                      </div>
                    </div>
                    <label className='fs-5 ms-2' >Durasi : {durationPkwt2Formated} Bulan</label>
                  </div>
                  <div className='d-flex flex-column w-50'>
                    <label className='ms-2 fw-bold fs-5'>No. P-PKWT</label>
                    <FloatingLabel label='Masukan No P-PKWT'>
                      <input type='text' placeholder='Masukan No P-PKWT' className='form-control' name='noPpkwt2' defaultValue={no_pPkwt_2} />
                    </FloatingLabel>
                    <div className='d-flex'>
                      <div className='d-flex flex-column w-50'>
                        <label className='ms-2 fw-bold fs-5'>Tanggal P-PKWT</label>
                        <FloatingLabel label='Masukan tgl P-PKWT'>
                          <input type='date' placeholder='Masukan tgl P-PKWT' className='form-control' name='datePpkwt2' defaultValue={akhir_pPkwt_2} onChange={(e) => setDatePpkwt2(e.target.value)} />
                        </FloatingLabel>
                      </div>
                      <label className='ms-4 mt-4 fs-5 d-flex justify-content-center align-items-center'>Durasi : {durationPpkwt2Formated} Bulan</label>
                    </div>
                    <label className='ms-2 fw-bold fs-5'>Catatan</label>
                    <FloatingLabel label='Masukan catatan jika ada'>
                      <textarea type='text' placeholder='Masukan catatan jika ada' className='form-control' name='notesPkwt2' defaultValue={catatan_pkwt_2} style={{ height: "90px" }} />
                    </FloatingLabel>
                  </div>
                  <div className='d-flex justify-content-center align-items-center'>
                    <button type='submit' className='btn btn-lg fs-2 h-25'>
                      <i className='bi bi-check-lg text-dark fw-bold border border-2 shadow shadow-sm p-2 rounded'></i>
                    </button>
                  </div>
                </div>
              </form>
            </Tab>
          )}
          {!isValueEmpty(no_pkwt_2) && (
            <Tab eventKey="contract_3" title="KONTRAK-3">
              <form className='p-2 bg-light mt-1 rounded d-flex flex-column gap-1 was-validated' onSubmit={handleFormPkwt}>
                <div className='d-flex gap-2 '>
                  <div className='d-flex flex-column w-50 '>
                    <label className='ms-2 fw-bold fs-5'>No. PKWT</label>
                    <FloatingLabel label='Masukan No PKWT'>
                      <input type='text' placeholder='Masukan No. PKWT' className='form-control' name='noPkwt3' defaultValue={no_pkwt_3} required />
                    </FloatingLabel>
                    <label className='ms-2 fw-bold fs-5'>Jabatan</label>
                    <FloatingLabel label='Masukan Jabatan'>
                      <input type='text' placeholder='Masukan Jabatan' className='form-control' name='positionPkwt3' defaultValue={jabatan_pkwt_3} required />
                    </FloatingLabel>
                    <div className='d-flex gap-2'>
                      <div className='d-flex flex-column w-50'>
                        <label className='ms-2 fw-bold fs-5'>Awal PKWT</label>
                        <FloatingLabel label='Masukan awal PKWT'>
                          <input type='date' placeholder='Masukan tgl awal PKWT' className='form-control' name='dateStartPkwt3' defaultValue={awal_pkwt_3} onChange={(e) => setDateStartPkwt3(e.target.value)} required />
                        </FloatingLabel>
                      </div>
                      <div className='d-flex flex-column w-50'>
                        <label className='ms-2 fw-bold fs-5'>Akhir PKWT</label>
                        <FloatingLabel label='Masukan akhir PKWT'>
                          <input type='date' placeholder='Masukan tgl akhir PKWT' className='form-control' name='dateEndPkwt3' defaultValue={akhir_pkwt_3} onChange={(e) => setDateEndPkwt3(e.target.value)} required />
                        </FloatingLabel>
                      </div>
                    </div>
                    <label className='fs-5 ms-2' >Durasi : {durationPkwt3Formated} Bulan</label>
                  </div>
                  <div className='d-flex flex-column w-50'>
                    <label className='ms-2 fw-bold fs-5'>No. P-PKWT</label>
                    <FloatingLabel label='Masukan No P-PKWT'>
                      <input type='text' placeholder='Masukan No P-PKWT' className='form-control' name='noPpkwt3' defaultValue={no_pPkwt_3} />
                    </FloatingLabel>
                    <div className='d-flex'>
                      <div className='d-flex flex-column w-50'>
                        <label className='ms-2 fw-bold fs-5'>Tanggal P-PKWT</label>
                        <FloatingLabel label='Masukan tgl P-PKWT'>
                          <input type='date' placeholder='Masukan tgl P-PKWT' className='form-control' name='datePpkwt3' defaultValue={akhir_pPkwt_3} onChange={(e) => setDatePpkwt3(e.target.value)} />
                        </FloatingLabel>
                      </div>
                      <label className='ms-4 mt-4 fs-5 d-flex justify-content-center align-items-center'>Durasi : {durationPpkwt3Formated} Bulan</label>
                    </div>
                    <label className='ms-2 fw-bold fs-5'>Catatan</label>
                    <FloatingLabel label='Masukan catatan jika ada'>
                      <textarea type='text' placeholder='Masukan catatan jika ada' className='form-control' name='notesPkwt3' defaultValue={catatan_pkwt_3} style={{ height: "90px" }} />
                    </FloatingLabel>
                  </div>
                  <div className='d-flex justify-content-center align-items-center'>
                    <button type='submit' className='btn btn-lg fs-2 h-25'>
                      <i className='bi bi-check-lg text-dark fw-bold border border-2 shadow shadow-sm p-2 rounded'></i>
                    </button>
                  </div>
                </div>
              </form>
            </Tab>
          )}
          {!isValueEmpty(no_pkwt_3) && (
            <Tab eventKey="contract_4" title="KONTRAK-4">
              <form className='p-2 bg-light mt-1 rounded d-flex flex-column gap-1 was-validated' onSubmit={handleFormPkwt}>
                <div className='d-flex gap-2'>
                  <div className='d-flex flex-column w-50'>
                    <label className='ms-2 fw-bold fs-5'>No. PKWT</label>
                    <FloatingLabel label='Masukan No PKWT'>
                      <input type='text' placeholder='Masukan No. PKWT' className='form-control' name='noPkwt4' defaultValue={no_pkwt_4} required />
                    </FloatingLabel>
                    <label className='ms-2 fw-bold fs-5'>Jabatan</label>
                    <FloatingLabel label='Masukan Jabatan'>
                      <input type='text' placeholder='Masukan Jabatan' className='form-control' name='positionPkwt4' defaultValue={jabatan_pkwt_4} required />
                    </FloatingLabel>
                  </div>
                  <div className='d-flex gap-2 w-50'>
                    <div className='d-flex flex-column w-100'>
                      <label className='ms-2 fw-bold fs-5'>Awal PKWT</label>
                      <FloatingLabel label='Masukan awal PKWT'>
                        <input type='date' placeholder='Masukan tgl awal PKWT' className='form-control' name='dateStartPkwt4' defaultValue={awal_pkwt_4} onChange={(e) => setDateStartPkwt4(e.target.value)} required />
                      </FloatingLabel>
                      <label className='ms-2 fw-bold fs-5'>Akhir PKWT</label>
                      <FloatingLabel label='Masukan akhir PKWT'>
                        <input type='date' placeholder='Masukan tgl akhir PKWT' className='form-control' name='dateEndPkwt4' defaultValue={akhir_pkwt_4} onChange={(e) => setDateEndPkwt4(e.target.value)} required />
                      </FloatingLabel>
                      <label className='fs-5 ms-2' >Durasi : {durationPkwt4Formated} Bulan</label>
                    </div>
                  </div>
                  <div className='d-flex flex-column w-50'>
                    <label className='ms-2 fw-bold fs-5'>Catatan</label>
                    <FloatingLabel label='Masukan catatan jika ada'>
                      <textarea type='text' placeholder='Masukan catatan jika ada' className='form-control' name='notesPkwt4' defaultValue={catatan_pkwt_4} style={{ height: "150px" }} />
                    </FloatingLabel>
                  </div>
                  <div className='d-flex justify-content-center align-items-center'>
                    <button type='submit' className='btn btn-lg fs-2 h-25'>
                      <i className='bi bi-check-lg text-dark fw-bold border border-2 shadow shadow-sm p-2 rounded'></i>
                    </button>
                  </div>
                </div>
              </form>
            </Tab>
          )}
          {!isValueEmpty(no_pkwt_4) && (
            <Tab eventKey="contract_5" title="KONTRAK-5">
              <form className='p-2 bg-light mt-1 rounded d-flex flex-column gap-1 was-validated' onSubmit={handleFormPkwt}>
                <div className='d-flex gap-2'>
                  <div className='d-flex flex-column w-50'>
                    <label className='ms-2 fw-bold fs-5'>No. PKWT</label>
                    <FloatingLabel label='Masukan No PKWT'>
                      <input type='text' placeholder='Masukan No. PKWT' className='form-control' name='noPkwt5' defaultValue={no_pkwt_5} required />
                    </FloatingLabel>
                    <label className='ms-2 fw-bold fs-5'>Jabatan</label>
                    <FloatingLabel label='Masukan Jabatan'>
                      <input type='text' placeholder='Masukan Jabatan' className='form-control' name='positionPkwt5' defaultValue={jabatan_pkwt_5} required />
                    </FloatingLabel>
                  </div>
                  <div className='d-flex gap-2 w-50'>
                    <div className='d-flex flex-column w-100'>
                      <label className='ms-2 fw-bold fs-5'>Awal PKWT</label>
                      <FloatingLabel label='Masukan awal PKWT'>
                        <input type='date' placeholder='Masukan tgl awal PKWT' className='form-control' name='dateStartPkwt5' defaultValue={awal_pkwt_5} onChange={(e) => setDateStartPkwt5(e.target.value)} required />
                      </FloatingLabel>
                      <label className='ms-2 fw-bold fs-5'>Akhir PKWT</label>
                      <FloatingLabel label='Masukan akhir PKWT'>
                        <input type='date' placeholder='Masukan tgl akhir PKWT' className='form-control' name='dateEndPkwt5' defaultValue={akhir_pkwt_5} onChange={(e) => setDateEndPkwt5(e.target.value)} required />
                      </FloatingLabel>
                      <label className='fs-5 ms-2' >Durasi : {durationPkwt5Formated} Bulan</label>
                    </div>
                  </div>
                  <div className='d-flex flex-column w-50'>
                    <label className='ms-2 fw-bold fs-5'>Catatan</label>
                    <FloatingLabel label='Masukan catatan jika ada'>
                      <textarea type='text' placeholder='Masukan catatan jika ada' className='form-control' name='notesPkwt5' defaultValue={catatan_pkwt_5} style={{ height: "150px" }} />
                    </FloatingLabel>
                  </div>
                  <div className='d-flex justify-content-center align-items-center'>
                    <button type='submit' className='btn btn-lg fs-2 h-25'>
                      <i className='bi bi-check-lg text-dark fw-bold border border-2 shadow shadow-sm p-2 rounded'></i>
                    </button>
                  </div>
                </div>
              </form>
            </Tab>
          )}
          {!isValueEmpty(no_pkwt_5) && (
            <Tab eventKey="contract_6" title="KONTRAK-6">
              <form className='p-2 bg-light mt-1 rounded d-flex flex-column gap-1 was-validated' onSubmit={handleFormPkwt}>
                <div className='d-flex gap-2'>
                  <div className='d-flex flex-column w-50'>
                    <label className='ms-2 fw-bold fs-5'>No. PKWT</label>
                    <FloatingLabel label='Masukan No PKWT'>
                      <input type='text' placeholder='Masukan No. PKWT' className='form-control' name='noPkwt6' defaultValue={no_pkwt_6} required />
                    </FloatingLabel>
                    <label className='ms-2 fw-bold fs-5'>Jabatan</label>
                    <FloatingLabel label='Masukan Jabatan'>
                      <input type='text' placeholder='Masukan Jabatan' className='form-control' name='positionPkwt6' defaultValue={jabatan_pkwt_6} required />
                    </FloatingLabel>
                  </div>
                  <div className='d-flex gap-2 w-50'>
                    <div className='d-flex flex-column w-100'>
                      <label className='ms-2 fw-bold fs-5'>Awal PKWT</label>
                      <FloatingLabel label='Masukan awal PKWT'>
                        <input type='date' placeholder='Masukan tgl awal PKWT' className='form-control' name='dateStartPkwt6' defaultValue={awal_pkwt_6} onChange={(e) => setDateStartPkwt6(e.target.value)} required />
                      </FloatingLabel>
                      <label className='ms-2 fw-bold fs-5'>Akhir PKWT</label>
                      <FloatingLabel label='Masukan akhir PKWT'>
                        <input type='date' placeholder='Masukan tgl akhir PKWT' className='form-control' name='dateEndPkwt6' defaultValue={akhir_pkwt_6} onChange={(e) => setDateEndPkwt6(e.target.value)} required />
                      </FloatingLabel>
                      <label className='fs-5 ms-2' >Durasi : {durationPkwt6Formated} Bulan</label>
                    </div>
                  </div>
                  <div className='d-flex flex-column w-50'>
                    <label className='ms-2 fw-bold fs-5'>Catatan</label>
                    <FloatingLabel label='Masukan catatan jika ada'>
                      <textarea type='text' placeholder='Masukan catatan jika ada' className='form-control' name='notesPkwt6' defaultValue={catatan_pkwt_6} style={{ height: "150px" }} />
                    </FloatingLabel>
                  </div>
                  <div className='d-flex justify-content-center align-items-center'>
                    <button type='submit' className='btn btn-lg fs-2 h-25'>
                      <i className='bi bi-check-lg text-dark fw-bold border border-2 shadow shadow-sm p-2 rounded'></i>
                    </button>
                  </div>
                </div>
              </form>
            </Tab>
          )}
          {!isValueEmpty(no_pkwt_6) && (
            <Tab eventKey="contract_7" title="KONTRAK-7">
              <form className='p-2 bg-light mt-1 rounded d-flex flex-column gap-1 was-validated' onSubmit={handleFormPkwt}>
                <div className='d-flex gap-2'>
                  <div className='d-flex flex-column w-50'>
                    <label className='ms-2 fw-bold fs-5'>No. PKWT</label>
                    <FloatingLabel label='Masukan No PKWT'>
                      <input type='text' placeholder='Masukan No. PKWT' className='form-control' name='noPkwt7' defaultValue={no_pkwt_7} required />
                    </FloatingLabel>
                    <label className='ms-2 fw-bold fs-5'>Jabatan</label>
                    <FloatingLabel label='Masukan Jabatan'>
                      <input type='text' placeholder='Masukan Jabatan' className='form-control' name='positionPkwt7' defaultValue={jabatan_pkwt_7} required />
                    </FloatingLabel>
                  </div>
                  <div className='d-flex gap-2 w-50'>
                    <div className='d-flex flex-column w-100'>
                      <label className='ms-2 fw-bold fs-5'>Awal PKWT</label>
                      <FloatingLabel label='Masukan awal PKWT'>
                        <input type='date' placeholder='Masukan tgl awal PKWT' className='form-control' name='dateStartPkwt7' defaultValue={awal_pkwt_7} onChange={(e) => setDateStartPkwt7(e.target.value)} required />
                      </FloatingLabel>
                      <label className='ms-2 fw-bold fs-5'>Akhir PKWT</label>
                      <FloatingLabel label='Masukan akhir PKWT'>
                        <input type='date' placeholder='Masukan tgl akhir PKWT' className='form-control' name='dateEndPkwt7' defaultValue={akhir_pkwt_7} onChange={(e) => setDateEndPkwt7(e.target.value)} required />
                      </FloatingLabel>
                      <label className='fs-5 ms-2' >Durasi : {durationPkwt7Formated} Bulan</label>
                    </div>
                  </div>
                  <div className='d-flex flex-column w-50'>
                    <label className='ms-2 fw-bold fs-5'>Catatan</label>
                    <FloatingLabel label='Masukan catatan jika ada'>
                      <textarea type='text' placeholder='Masukan catatan jika ada' className='form-control' name='notesPkwt7' defaultValue={catatan_pkwt_7} style={{ height: "150px" }} />
                    </FloatingLabel>
                  </div>
                  <div className='d-flex justify-content-center align-items-center'>
                    <button type='submit' className='btn btn-lg fs-2 h-25'>
                      <i className='bi bi-check-lg text-dark fw-bold border border-2 shadow shadow-sm p-2 rounded'></i>
                    </button>
                  </div>
                </div>
              </form>
            </Tab>
          )}
          {!isValueEmpty(no_pkwt_7) && (
            <Tab eventKey="contract_8" title="KONTRAK-8">
              <form className='p-2 bg-light mt-1 rounded d-flex flex-column gap-1 was-validated' onSubmit={handleFormPkwt}>
                <div className='d-flex gap-2'>
                  <div className='d-flex flex-column w-50'>
                    <label className='ms-2 fw-bold fs-5'>No. PKWT</label>
                    <FloatingLabel label='Masukan No PKWT'>
                      <input type='text' placeholder='Masukan No. PKWT' className='form-control' name='noPkwt8' defaultValue={no_pkwt_8} required />
                    </FloatingLabel>
                    <label className='ms-2 fw-bold fs-5'>Jabatan</label>
                    <FloatingLabel label='Masukan Jabatan'>
                      <input type='text' placeholder='Masukan Jabatan' className='form-control' name='positionPkwt8' defaultValue={jabatan_pkwt_8} required />
                    </FloatingLabel>
                  </div>
                  <div className='d-flex gap-2 w-50'>
                    <div className='d-flex flex-column w-100'>
                      <label className='ms-2 fw-bold fs-5'>Awal PKWT</label>
                      <FloatingLabel label='Masukan awal PKWT'>
                        <input type='date' placeholder='Masukan tgl awal PKWT' className='form-control' name='dateStartPkwt8' defaultValue={awal_pkwt_8} onChange={(e) => setDateStartPkwt8(e.target.value)} required />
                      </FloatingLabel>
                      <label className='ms-2 fw-bold fs-5'>Akhir PKWT</label>
                      <FloatingLabel label='Masukan akhir PKWT'>
                        <input type='date' placeholder='Masukan tgl akhir PKWT' className='form-control' name='dateEndPkwt8' defaultValue={akhir_pkwt_8} onChange={(e) => setDateEndPkwt8(e.target.value)} required />
                      </FloatingLabel>
                      <label className='fs-5 ms-2' >Durasi : {durationPkwt8Formated} Bulan</label>
                    </div>
                  </div>
                  <div className='d-flex flex-column w-50'>
                    <label className='ms-2 fw-bold fs-5'>Catatan</label>
                    <FloatingLabel label='Masukan catatan jika ada'>
                      <textarea type='text' placeholder='Masukan catatan jika ada' className='form-control' name='notesPkwt8' defaultValue={catatan_pkwt_8} style={{ height: "150px" }} />
                    </FloatingLabel>
                  </div>
                  <div className='d-flex justify-content-center align-items-center'>
                    <button type='submit' className='btn btn-lg fs-2 h-25'>
                      <i className='bi bi-check-lg text-dark fw-bold border border-2 shadow shadow-sm p-2 rounded'></i>
                    </button>
                  </div>
                </div>
              </form>
            </Tab>
          )}
          {!isValueEmpty(no_pkwt_8) && (
            <Tab eventKey="contract_9" title="KONTRAK-9">
              <form className='p-2 bg-light mt-1 rounded d-flex flex-column gap-1 was-validated' onSubmit={handleFormPkwt}>
                <div className='d-flex gap-2'>
                  <div className='d-flex flex-column w-50'>
                    <label className='ms-2 fw-bold fs-5'>No. PKWT</label>
                    <FloatingLabel label='Masukan No PKWT'>
                      <input type='text' placeholder='Masukan No. PKWT' className='form-control' name='noPkwt9' defaultValue={no_pkwt_9} required />
                    </FloatingLabel>
                    <label className='ms-2 fw-bold fs-5'>Jabatan</label>
                    <FloatingLabel label='Masukan Jabatan'>
                      <input type='text' placeholder='Masukan Jabatan' className='form-control' name='positionPkwt9' defaultValue={jabatan_pkwt_9} required />
                    </FloatingLabel>
                  </div>
                  <div className='d-flex gap-2 w-50'>
                    <div className='d-flex flex-column w-100'>
                      <label className='ms-2 fw-bold fs-5'>Awal PKWT</label>
                      <FloatingLabel label='Masukan awal PKWT'>
                        <input type='date' placeholder='Masukan tgl awal PKWT' className='form-control' name='dateStartPkwt9' defaultValue={awal_pkwt_9} onChange={(e) => setDateStartPkwt9(e.target.value)} required />
                      </FloatingLabel>
                      <label className='ms-2 fw-bold fs-5'>Akhir PKWT</label>
                      <FloatingLabel label='Masukan akhir PKWT'>
                        <input type='date' placeholder='Masukan tgl akhir PKWT' className='form-control' name='dateEndPkwt9' defaultValue={akhir_pkwt_9} onChange={(e) => setDateEndPkwt9(e.target.value)} required />
                      </FloatingLabel>
                      <label className='fs-5 ms-2' >Durasi : {durationPkwt9Formated} Bulan</label>
                    </div>
                  </div>
                  <div className='d-flex flex-column w-50'>
                    <label className='ms-2 fw-bold fs-5'>Catatan</label>
                    <FloatingLabel label='Masukan catatan jika ada'>
                      <textarea type='text' placeholder='Masukan catatan jika ada' className='form-control' name='notesPkwt9' defaultValue={catatan_pkwt_9} style={{ height: "150px" }} />
                    </FloatingLabel>
                  </div>
                  <div className='d-flex justify-content-center align-items-center'>
                    <button type='submit' className='btn btn-lg fs-2 h-25'>
                      <i className='bi bi-check-lg text-dark fw-bold border border-2 shadow shadow-sm p-2 rounded'></i>
                    </button>
                  </div>
                </div>
              </form>
            </Tab>
          )}
          {!isValueEmpty(no_pkwt_9) && (
            <Tab eventKey="contract_10" title="KONTRAK-10">
              <form className='p-2 bg-light mt-1 rounded d-flex flex-column gap-1 was-validated' onSubmit={handleFormPkwt}>
                <div className='d-flex gap-2'>
                  <div className='d-flex flex-column w-50'>
                    <label className='ms-2 fw-bold fs-5'>No. PKWT</label>
                    <FloatingLabel label='Masukan No PKWT'>
                      <input type='text' placeholder='Masukan No. PKWT' className='form-control' name='noPkwt10' defaultValue={no_pkwt_10} required />
                    </FloatingLabel>
                    <label className='ms-2 fw-bold fs-5'>Jabatan</label>
                    <FloatingLabel label='Masukan Jabatan'>
                      <input type='text' placeholder='Masukan Jabatan' className='form-control' name='positionPkwt10' defaultValue={jabatan_pkwt_10} required />
                    </FloatingLabel>
                  </div>
                  <div className='d-flex gap-2 w-50'>
                    <div className='d-flex flex-column w-100'>
                      <label className='ms-2 fw-bold fs-5'>Awal PKWT</label>
                      <FloatingLabel label='Masukan awal PKWT'>
                        <input type='date' placeholder='Masukan tgl awal PKWT' className='form-control' name='dateStartPkwt10' defaultValue={awal_pkwt_10} onChange={(e) => setDateStartPkwt10(e.target.value)} required />
                      </FloatingLabel>
                      <label className='ms-2 fw-bold fs-5'>Akhir PKWT</label>
                      <FloatingLabel label='Masukan akhir PKWT'>
                        <input type='date' placeholder='Masukan tgl akhir PKWT' className='form-control' name='dateEndPkwt10' defaultValue={akhir_pkwt_10} onChange={(e) => setDateEndPkwt10(e.target.value)} required />
                      </FloatingLabel>
                      <label className='fs-5 ms-2' >Durasi : {durationPkwt10Formated} Bulan</label>
                    </div>
                  </div>
                  <div className='d-flex flex-column w-50'>
                    <label className='ms-2 fw-bold fs-5'>Catatan</label>
                    <FloatingLabel label='Masukan catatan jika ada'>
                      <textarea type='text' placeholder='Masukan catatan jika ada' className='form-control' name='notesPkwt10' defaultValue={catatan_pkwt_10} style={{ height: "150px" }} />
                    </FloatingLabel>
                  </div>
                  <div className='d-flex justify-content-center align-items-center'>
                    <button type='submit' className='btn btn-lg fs-2 h-25'>
                      <i className='bi bi-check-lg text-dark fw-bold border border-2 shadow shadow-sm p-2 rounded'></i>
                    </button>
                  </div>
                </div>
              </form>
            </Tab>
          )}
          {!isValueEmpty(no_pkwt_10) && (
            <Tab eventKey="contract_11" title="KONTRAK-11">
              <form className='p-2 bg-light mt-1 rounded d-flex flex-column gap-1 was-validated' onSubmit={handleFormPkwt}>
                <div className='d-flex gap-2'>
                  <div className='d-flex flex-column w-50'>
                    <label className='ms-2 fw-bold fs-5'>No. PKWT</label>
                    <FloatingLabel label='Masukan No PKWT'>
                      <input type='text' placeholder='Masukan No. PKWT' className='form-control' name='noPkwt11' defaultValue={no_pkwt_11} required />
                    </FloatingLabel>
                    <label className='ms-2 fw-bold fs-5'>Jabatan</label>
                    <FloatingLabel label='Masukan Jabatan'>
                      <input type='text' placeholder='Masukan Jabatan' className='form-control' name='positionPkwt11' defaultValue={jabatan_pkwt_11} required />
                    </FloatingLabel>
                  </div>
                  <div className='d-flex gap-2 w-50'>
                    <div className='d-flex flex-column w-100'>
                      <label className='ms-2 fw-bold fs-5'>Awal PKWT</label>
                      <FloatingLabel label='Masukan awal PKWT'>
                        <input type='date' placeholder='Masukan tgl awal PKWT' className='form-control' name='dateStartPkwt11' defaultValue={awal_pkwt_11} onChange={(e) => setDateStartPkwt11(e.target.value)} required />
                      </FloatingLabel>
                      <label className='ms-2 fw-bold fs-5'>Akhir PKWT</label>
                      <FloatingLabel label='Masukan akhir PKWT'>
                        <input type='date' placeholder='Masukan tgl akhir PKWT' className='form-control' name='dateEndPkwt11' defaultValue={akhir_pkwt_11} onChange={(e) => setDateEndPkwt11(e.target.value)} required />
                      </FloatingLabel>
                      <label className='fs-5 ms-2' >Durasi : {durationPkwt11Formated} Bulan</label>
                    </div>
                  </div>
                  <div className='d-flex flex-column w-50'>
                    <label className='ms-2 fw-bold fs-5'>Catatan</label>
                    <FloatingLabel label='Masukan catatan jika ada'>
                      <textarea type='text' placeholder='Masukan catatan jika ada' className='form-control' name='notesPkwt11' defaultValue={catatan_pkwt_11} style={{ height: "150px" }} />
                    </FloatingLabel>
                  </div>
                  <div className='d-flex justify-content-center align-items-center'>
                    <button type='submit' className='btn btn-lg fs-2 h-25'>
                      <i className='bi bi-check-lg text-dark fw-bold border border-2 shadow shadow-sm p-2 rounded'></i>
                    </button>
                  </div>
                </div>
              </form>
            </Tab>
          )}
          {!isValueEmpty(no_pkwt_11) && (
            <Tab eventKey="contract_12" title="KONTRAK-12">
              <form className='p-2 bg-light mt-1 rounded d-flex flex-column gap-1 was-validated' onSubmit={handleFormPkwt}>
                <div className='d-flex gap-2'>
                  <div className='d-flex flex-column w-50'>
                    <label className='ms-2 fw-bold fs-5'>No. PKWT</label>
                    <FloatingLabel label='Masukan No PKWT'>
                      <input type='text' placeholder='Masukan No. PKWT' className='form-control' name='noPkwt12' defaultValue={no_pkwt_12} required />
                    </FloatingLabel>
                    <label className='ms-2 fw-bold fs-5'>Jabatan</label>
                    <FloatingLabel label='Masukan Jabatan'>
                      <input type='text' placeholder='Masukan Jabatan' className='form-control' name='positionPkwt12' defaultValue={jabatan_pkwt_12} required />
                    </FloatingLabel>
                  </div>
                  <div className='d-flex gap-2 w-50'>
                    <div className='d-flex flex-column w-100'>
                      <label className='ms-2 fw-bold fs-5'>Awal PKWT</label>
                      <FloatingLabel label='Masukan awal PKWT'>
                        <input type='date' placeholder='Masukan tgl awal PKWT' className='form-control' name='dateStartPkwt12' defaultValue={awal_pkwt_12} onChange={(e) => setDateStartPkwt12(e.target.value)} required />
                      </FloatingLabel>
                      <label className='ms-2 fw-bold fs-5'>Akhir PKWT</label>
                      <FloatingLabel label='Masukan akhir PKWT'>
                        <input type='date' placeholder='Masukan tgl akhir PKWT' className='form-control' name='dateEndPkwt12' defaultValue={akhir_pkwt_12} onChange={(e) => setDateEndPkwt12(e.target.value)} required />
                      </FloatingLabel>
                      <label className='fs-5 ms-2' >Durasi : {durationPkwt12Formated} Bulan</label>
                    </div>
                  </div>
                  <div className='d-flex flex-column w-50'>
                    <label className='ms-2 fw-bold fs-5'>Catatan</label>
                    <FloatingLabel label='Masukan catatan jika ada'>
                      <textarea type='text' placeholder='Masukan catatan jika ada' className='form-control' name='notesPkwt12' defaultValue={catatan_pkwt_12} style={{ height: "150px" }} />
                    </FloatingLabel>
                  </div>
                  <div className='d-flex justify-content-center align-items-center'>
                    <button type='submit' className='btn btn-lg fs-2 h-25'>
                      <i className='bi bi-check-lg text-dark fw-bold border border-2 shadow shadow-sm p-2 rounded'></i>
                    </button>
                  </div>
                </div>
              </form>
            </Tab>
          )}
          <Tab eventKey="history_position" title="RIWAYAT JABATAN">
            <form onSubmit={handleFormPkwt}>
              <label className='ms-2 fw-bold fs-5'>Riwayat Jabatan</label>
              <div className='d-flex justify-content-between align-items-center'>
                <FloatingLabel label="Masukan riwayat kontrak" className='w-100'>
                  <input type='text' name='historyPosition' defaultValue={riwayat_jabatan} className='form-control' placeholder='Masukan riwayat kontrak' />
                </FloatingLabel>
                <button type='submit' className='btn btn-lg fs-1'>
                  <i className='bi bi-check-lg text-dark fw-bold border border-2 shadow shadow-sm rounded'></i>
                </button>
              </div>
            </form>
          </Tab>
        </Tabs>
      </div>
    </>
  )
}

export default UpdatePkwt
