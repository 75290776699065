import React from 'react'
import { months } from '../../../Components/Utility'

const HOK = ({ filterDataPerformanceByNik, year }) => {

  const dataHOKByMonth = months.map((month) => {
    const data = filterDataPerformanceByNik('hok', month, year);
    if (data.length) {
      const value = parseFloat(data[0].value);
      return Number.isInteger(value) ? value : value.toFixed(2);
    }
    return '-';
  });

  const total = dataHOKByMonth.reduce((acc, value) => {
    return value !== '-' ? acc + parseFloat(value) : acc;
  }, 0);

  return (
    <tbody>
      <tr className='text-center'>
        <td className='fw-bold' colSpan={2}>HOK</td>
        {dataHOKByMonth.map((value, i) => (
          <td key={i}>{value !== '-' ? `${value} ` : value}</td>
        ))}
        <td style={{ fontWeight: 'bold' }}>{total !== 0 ? `${total} hari` : '-'}</td>
      </tr>
    </tbody>
  );
};


export default HOK