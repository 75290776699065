import React, { useState } from 'react';
import NavigationBar from '../../../Components/Utility/Navbar';
import logoBK from '../../../Assets/Logo/BK.png';
import { useLocation } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import HOK from './HOK';
import JOK from './JOK';
import Late from './Late';
import Absent from './Absent';
import Permission from './Permission';
import Sick from './Sick';
import GoHome from './GoHome';
import KPI from './KPI';
import DR from './DR';
import Evaluation from './Evaluation';

const RekapPerformanceBK = ({ title }) => {
  const location = useLocation();
  const dataPerformance = location.state.dataPerformance;
  const dataEmployees = location.state.dataEmployees;
  const [showStatus, setShowStatus] = useState(false);

  const dataEmployeeFiltered = dataEmployees.filter(
    (item) =>
      item.unit.toLowerCase() === 'bara kumala' &&
      (showStatus || item.status.toLowerCase() === 'aktif')
  );

  const performanceByYear = dataPerformance.reduce((acc, item) => {
    const year = new Date(item.periode).getFullYear();
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(item);
    return acc;
  }, {});

  const yearList = Object.keys(performanceByYear).sort((a, b) => b - a);

  return (
    <div>
      <NavigationBar logoBK={logoBK} title={title} />
      <div className='m-2 mt-2 animate__animated animate__fadeInDown'>
        <div className='form-check position-fixed end-0 me-5' onChange={() => setShowStatus(!showStatus)}>
          <input className='form-check-input' type='checkbox' defaultChecked={showStatus} />
          <label className='form-check-label text-danger text-decoration-underline'>NON AKTIF</label>
        </div>

        <Tabs defaultActiveKey={yearList[0]} className='mt-1 mb-2'>
          {yearList.map((year) => (
            <Tab eventKey={year} title={year} key={year}>
              <Tabs defaultActiveKey={'HOK'} className='mt-1 mb-2'>
                <Tab eventKey={'HOK'} title='HOK'>
                  <HOK
                    dataEmployeeFiltered={dataEmployeeFiltered}
                    dataPerformance={performanceByYear[year]}
                  />
                </Tab>
                <Tab eventKey={'JOK'} title='JOK'>
                  <JOK
                    dataEmployeeFiltered={dataEmployeeFiltered}
                    dataPerformance={performanceByYear[year]}
                  />
                </Tab>
                <Tab eventKey={'LATE'} title='Terlambat'>
                  <Late
                    dataEmployeeFiltered={dataEmployeeFiltered}
                    dataPerformance={performanceByYear[year]}
                  />
                </Tab>
                <Tab eventKey={'ABSENT'} title='Mangkir'>
                  <Absent
                    dataEmployeeFiltered={dataEmployeeFiltered}
                    dataPerformance={performanceByYear[year]}
                  />
                </Tab>
                <Tab eventKey={'Permission'} title='Izin'>
                  <Permission
                    dataEmployeeFiltered={dataEmployeeFiltered}
                    dataPerformance={performanceByYear[year]}
                  />
                </Tab>
                <Tab eventKey={'Sick'} title='Sakit'>
                  <Sick
                    dataEmployeeFiltered={dataEmployeeFiltered}
                    dataPerformance={performanceByYear[year]}
                  />
                </Tab>
                <Tab eventKey={'GoHome'} title='Pulang Cepat'>
                  <GoHome
                    dataEmployeeFiltered={dataEmployeeFiltered}
                    dataPerformance={performanceByYear[year]}
                  />
                </Tab>
                <Tab eventKey={'KPI'} title='KPI'>
                  <KPI
                    dataEmployeeFiltered={dataEmployeeFiltered}
                    dataPerformance={performanceByYear[year]}
                  />
                </Tab>
                <Tab eventKey={'DR'} title='Daily Report'>
                  <DR
                    dataEmployeeFiltered={dataEmployeeFiltered}
                    dataPerformance={performanceByYear[year]}
                  />
                </Tab>
                <Tab eventKey={'Evaluation'} title='Nilai Atasan'>
                  <Evaluation
                    dataEmployeeFiltered={dataEmployeeFiltered}
                    dataPerformance={performanceByYear[year]}
                  />
                </Tab>
              </Tabs>
            </Tab>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default RekapPerformanceBK;
