import React from 'react'
import UpdateProfile from './UpdateProfile'
import UpdateID from './UpdateID'
import UpdateFoto from './UpdateFoto'
import NavigationBar from '../../Components/Utility/Navbar'
import UpdatePkwt from '../../PKWT/UpdatePkwt'
import UpdatePerformance from '../../Performance/Update'
import UpdateVacation from '../../Vacation/Update'
import UpdateFamily from '../../Family/Update'
import UpdateWarningLetter from '../../WarningLetter/Update'
import UpdatePrestasi from '../../Prestasi/Update'
import { useLocation } from 'react-router-dom'
import { Accordion } from 'react-bootstrap'
import UpdateTraining from '../../Training/Update'
import UpdateDrivingLicense from '../../DrivingLicense/Update'
import UpdateNotes from '../../Notes/Update'

const UpdateDataEmployee = ({ title }) => {

  const location = useLocation()
  const dataDetailEmployees = location.state.dataDetailEmployees
  const dataVacationByNik = location.state.dataVacationByNik
  const dataFamilyByNik = location.state.dataFamilyByNik
  const dataWarningLetterByNik = location.state.dataWarningLetterByNik
  const dataPrestasiByNik = location.state.dataPrestasiByNik
  const dataTrainingByNik = location.state.dataTrainingByNik
  const dataDrivingLicenseByNik = location.state.dataDrivingLicenseByNik
  const dataNotesByNik = location.state.dataNotesByNik
  const { nama_lengkap } = dataDetailEmployees
  const dataVacation = dataVacationByNik.length === 0;
  const dataFamily = dataFamilyByNik.length === 0;
  const dataWarningLetter = dataWarningLetterByNik.length === 0;
  const dataPrestasi = dataPrestasiByNik.length === 0;
  const dataTraining = dataTrainingByNik.length === 0;
  const dataLicense = dataDrivingLicenseByNik.length === 0;
  const dataNotes = dataNotesByNik.length === 0;

  return (
    <>
      <NavigationBar title={title} namaLengkap={nama_lengkap} />
      <Accordion className='container mt-1 d-flex flex-column gap-2 animate__animated animate__fadeInDown mb-2'>
        <Accordion.Item eventKey="0">
          <Accordion.Header className='title-h1'>Edit Data Karyawan</Accordion.Header>
          <Accordion.Body>
            <UpdateProfile />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header className='title-h1'>Edit Foto Karyawan</Accordion.Header>
          <Accordion.Body>
            <UpdateFoto />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header className='title-h1'>Edit ID Karyawan</Accordion.Header>
          <Accordion.Body>
            <UpdateID />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header className='title-h1'>Input/Edit Masa Kerja</Accordion.Header>
          <Accordion.Body>
            <UpdatePkwt dataDetailEmployees={dataDetailEmployees} />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header className='title-h1'>Edit Penilaian Karyawan</Accordion.Header>
          <Accordion.Body>
            <UpdatePerformance dataDetailEmployees={dataDetailEmployees} />
          </Accordion.Body>
        </Accordion.Item>
        {!dataVacation &&
          <Accordion.Item eventKey="5">
            <Accordion.Header className='title-h1'>Edit Masa Cuti</Accordion.Header>
            <Accordion.Body>
              <UpdateVacation dataDetailEmployees={dataDetailEmployees} />
            </Accordion.Body>
          </Accordion.Item>
        }
        {!dataFamily &&
          <Accordion.Item eventKey="6">
            <Accordion.Header className='title-h1'>Edit Data Keluarga</Accordion.Header>
            <Accordion.Body>
              <UpdateFamily dataDetailEmployees={dataDetailEmployees} />
            </Accordion.Body>
          </Accordion.Item>
        }
        {!dataWarningLetter &&
          <Accordion.Item eventKey="7">
            <Accordion.Header className='title-h1'>Edit Surat Peringatan</Accordion.Header>
            <Accordion.Body>
              <UpdateWarningLetter dataDetailEmployees={dataDetailEmployees} />
            </Accordion.Body>
          </Accordion.Item>
        }
        {!dataPrestasi &&
          <Accordion.Item eventKey="8">
            <Accordion.Header className='title-h1'>Edit Prestasi</Accordion.Header>
            <Accordion.Body>
              <UpdatePrestasi dataDetailEmployees={dataDetailEmployees} />
            </Accordion.Body>
          </Accordion.Item>
        }
        {!dataTraining &&
          <Accordion.Item eventKey="9">
            <Accordion.Header className='title-h1'>Edit Training</Accordion.Header>
            <Accordion.Body>
              <UpdateTraining dataDetailEmployees={dataDetailEmployees} />
            </Accordion.Body>
          </Accordion.Item>
        }
        {!dataLicense &&
          <Accordion.Item eventKey="10">
            <Accordion.Header className='title-h1'>Edit SimPol</Accordion.Header>
            <Accordion.Body>
              <UpdateDrivingLicense dataDetailEmployees={dataDetailEmployees} />
            </Accordion.Body>
          </Accordion.Item>
        }
        {!dataNotes &&
          <Accordion.Item eventKey="11">
            <Accordion.Header className='title-h1'>Edit Catatan</Accordion.Header>
            <Accordion.Body>
              <UpdateNotes />
            </Accordion.Body>
          </Accordion.Item>
        }
      </Accordion>
    </>
  )
}

export default UpdateDataEmployee