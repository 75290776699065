import React from 'react'

const Evaluation = ({ filterDataPerformanceByNik, year }) => {

  const quarterMonths = [
    '03-01',
    '06-01',
    '09-01',
    '12-01',
  ]

  const dataEvaluation = quarterMonths.map((date) => {
    const data = filterDataPerformanceByNik('nilai', date, year)
    if (data.length) {
      const value = parseFloat(data[0].value)
      return Number.isInteger(value) ? value : value.toFixed(2)
    }
    return '-';
  })


  const validValues = dataEvaluation.filter(value => value !== '-').map(value => parseFloat(value))
  const total = validValues.reduce((acc, value) => acc + value, 0)
  const average = (validValues.length > 0 ? (total / validValues.length) : 0).toFixed(2)

  return (
    <tbody>
      <tr className='text-center table-secondary border-dark'>
        <td className='fw-bold' colSpan={2}>Nilai Atasan</td>
        {dataEvaluation.map((date, i) => {
          return (
            <td colSpan={3} key={i}>{date}</td>
          )
        })}
        <td>{average !== '0.00' ? `${average}` : '-'}</td>
      </tr>
    </tbody>
  )
}

export default Evaluation