import React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { dateFormated, isValueEmpty, changeDurationMonth, formatTimeRemaining } from '../Components/Utility'

const PKWT = ({ dataDetailEmployees }) => {

  const { nik, status,
    no_pkwt_1, awal_pkwt_1, akhir_pkwt_1, no_pPkwt_1, akhir_pPkwt_1, jabatan_pkwt_1, catatan_pkwt_1,
    no_pkwt_2, awal_pkwt_2, akhir_pkwt_2, no_pPkwt_2, akhir_pPkwt_2, jabatan_pkwt_2, catatan_pkwt_2,
    no_pkwt_3, awal_pkwt_3, akhir_pkwt_3, no_pPkwt_3, akhir_pPkwt_3, jabatan_pkwt_3, catatan_pkwt_3,
    no_pkwt_4, awal_pkwt_4, akhir_pkwt_4, jabatan_pkwt_4, catatan_pkwt_4,
    no_pkwt_5, awal_pkwt_5, akhir_pkwt_5, jabatan_pkwt_5, catatan_pkwt_5,
    no_pkwt_6, awal_pkwt_6, akhir_pkwt_6, jabatan_pkwt_6, catatan_pkwt_6,
    no_pkwt_7, awal_pkwt_7, akhir_pkwt_7, jabatan_pkwt_7, catatan_pkwt_7,
    no_pkwt_8, awal_pkwt_8, akhir_pkwt_8, jabatan_pkwt_8, catatan_pkwt_8,
    no_pkwt_9, awal_pkwt_9, akhir_pkwt_9, jabatan_pkwt_9, catatan_pkwt_9,
    no_pkwt_10, awal_pkwt_10, akhir_pkwt_10, jabatan_pkwt_10, catatan_pkwt_10,
    no_pkwt_11, awal_pkwt_11, akhir_pkwt_11, jabatan_pkwt_11, catatan_pkwt_11,
    no_pkwt_12, awal_pkwt_12, akhir_pkwt_12, jabatan_pkwt_12, catatan_pkwt_12,
    ikatan_kerja, riwayat_jabatan
  } = dataDetailEmployees

  const dates = [
    awal_pkwt_1, akhir_pkwt_1, akhir_pPkwt_1,
    awal_pkwt_2, akhir_pkwt_2, akhir_pPkwt_2,
    awal_pkwt_3, akhir_pkwt_3, akhir_pPkwt_3,
    awal_pkwt_4, akhir_pkwt_4,
    awal_pkwt_5, akhir_pkwt_5,
    awal_pkwt_6, akhir_pkwt_6,
    awal_pkwt_7, akhir_pkwt_7,
    awal_pkwt_8, akhir_pkwt_8,
    awal_pkwt_9, akhir_pkwt_9,
    awal_pkwt_10, akhir_pkwt_10,
    awal_pkwt_11, akhir_pkwt_11,
    awal_pkwt_12, akhir_pkwt_12
  ].map(dateFormated)

  const [
    dateStartPkwt1, dateEndPkwt1, dateEndPpkwt1,
    dateStartPkwt2, dateEndPkwt2, dateEndPpkwt2,
    dateStartPkwt3, dateEndPkwt3, dateEndPpkwt3,
    dateStartPkwt4, dateEndPkwt4,
    dateStartPkwt5, dateEndPkwt5,
    dateStartPkwt6, dateEndPkwt6,
    dateStartPkwt7, dateEndPkwt7,
    dateStartPkwt8, dateEndPkwt8,
    dateStartPkwt9, dateEndPkwt9,
    dateStartPkwt10, dateEndPkwt10,
    dateStartPkwt11, dateEndPkwt11,
    dateStartPkwt12, dateEndPkwt12,
  ] = dates

  const durationPkwt1 = (new Date(akhir_pkwt_1) - new Date(awal_pkwt_1)) / changeDurationMonth
  const durationPpkwt1 = (new Date(akhir_pPkwt_1) - new Date(awal_pkwt_1)) / changeDurationMonth
  const durationPkwt2 = (new Date(akhir_pkwt_2) - new Date(awal_pkwt_2)) / changeDurationMonth
  const durationPpkwt2 = (new Date(akhir_pPkwt_2) - new Date(awal_pkwt_2)) / changeDurationMonth
  const durationPkwt3 = (new Date(akhir_pkwt_3) - new Date(awal_pkwt_3)) / changeDurationMonth
  const durationPpkwt3 = (new Date(akhir_pPkwt_3) - new Date(awal_pkwt_3)) / changeDurationMonth
  const durationPkwt4 = (new Date(akhir_pkwt_4) - new Date(awal_pkwt_4)) / changeDurationMonth
  const durationPkwt5 = (new Date(akhir_pkwt_5) - new Date(awal_pkwt_5)) / changeDurationMonth
  const durationPkwt6 = (new Date(akhir_pkwt_6) - new Date(awal_pkwt_6)) / changeDurationMonth
  const durationPkwt7 = (new Date(akhir_pkwt_7) - new Date(awal_pkwt_7)) / changeDurationMonth
  const durationPkwt8 = (new Date(akhir_pkwt_8) - new Date(awal_pkwt_8)) / changeDurationMonth
  const durationPkwt9 = (new Date(akhir_pkwt_9) - new Date(awal_pkwt_9)) / changeDurationMonth
  const durationPkwt10 = (new Date(akhir_pkwt_10) - new Date(awal_pkwt_10)) / changeDurationMonth
  const durationPkwt11 = (new Date(akhir_pkwt_11) - new Date(awal_pkwt_11)) / changeDurationMonth
  const durationPkwt12 = (new Date(akhir_pkwt_12) - new Date(awal_pkwt_12)) / changeDurationMonth

  const selectionNoPkwt = no_pkwt_12 || no_pkwt_11 || no_pkwt_10 || no_pkwt_9 || no_pkwt_8 || no_pkwt_7 || no_pkwt_6 || no_pkwt_5 || no_pkwt_4 || no_pPkwt_3 || no_pkwt_3 || no_pPkwt_2 || no_pkwt_2 || no_pPkwt_1 || no_pkwt_1

  const isDefaultDate = (date) => {
    return date === "1 Jan 1970" || isNaN(Date.parse(date));
  }

  const selectionDateStartPkwt = [
    dateStartPkwt12, dateStartPkwt11, dateStartPkwt10, dateStartPkwt11, dateStartPkwt8,
    dateStartPkwt7, dateStartPkwt6, dateStartPkwt5, dateStartPkwt4,
    dateStartPkwt3, dateStartPkwt2, dateStartPkwt1
  ].filter(date => !isNaN(Date.parse(date))).find(date => !isDefaultDate(date)) || null;

  const selectionDateEndPkwt = [
    dateEndPkwt12, dateEndPkwt11, dateEndPkwt10, dateEndPkwt9, dateEndPkwt8,
    dateEndPkwt7, dateEndPkwt6, dateEndPkwt5, dateEndPkwt4, dateEndPkwt4,
    dateEndPkwt3, dateEndPpkwt3, dateEndPkwt2, dateEndPpkwt2, dateEndPkwt1, dateEndPpkwt1
  ].filter(date => !isNaN(Date.parse(date))).find(date => !isDefaultDate(date)) || null;

  const durationPkwtActive = (new Date(selectionDateEndPkwt) - new Date(selectionDateStartPkwt)) / changeDurationMonth

  const remainingDurationPkwtActive = (new Date(selectionDateEndPkwt) - new Date())

  const selectionPositionPkwt = jabatan_pkwt_12 || jabatan_pkwt_11 || jabatan_pkwt_10 || jabatan_pkwt_9 || jabatan_pkwt_8 || jabatan_pkwt_7 || jabatan_pkwt_6 || jabatan_pkwt_5 || jabatan_pkwt_4 || jabatan_pkwt_3 || jabatan_pkwt_2 || jabatan_pkwt_1

  let totalDurationPkwt = 0;
  totalDurationPkwt += (durationPpkwt1 !== null && durationPpkwt1 >= 0 && !isNaN(durationPpkwt1)) ? durationPpkwt1 : (durationPkwt1 !== null && !isNaN(durationPkwt1)) ? durationPkwt1 : 0;
  totalDurationPkwt += (durationPpkwt2 !== null && durationPpkwt2 >= 0 && !isNaN(durationPpkwt2)) ? durationPpkwt2 : (durationPkwt2 !== null && !isNaN(durationPkwt2)) ? durationPkwt2 : 0;
  totalDurationPkwt += (durationPpkwt3 !== null && durationPpkwt3 >= 0 && !isNaN(durationPpkwt3)) ? durationPpkwt3 : (durationPkwt3 !== null && !isNaN(durationPkwt3)) ? durationPkwt3 : 0;
  totalDurationPkwt += !isNaN(durationPkwt4) ? durationPkwt4 : 0;
  totalDurationPkwt += !isNaN(durationPkwt5) ? durationPkwt5 : 0;
  totalDurationPkwt += !isNaN(durationPkwt6) ? durationPkwt6 : 0;
  totalDurationPkwt += !isNaN(durationPkwt7) ? durationPkwt7 : 0;
  totalDurationPkwt += !isNaN(durationPkwt8) ? durationPkwt8 : 0;
  totalDurationPkwt += !isNaN(durationPkwt9) ? durationPkwt9 : 0;
  totalDurationPkwt += !isNaN(durationPkwt10) ? durationPkwt10 : 0;
  totalDurationPkwt += !isNaN(durationPkwt11) ? durationPkwt11 : 0;
  totalDurationPkwt += !isNaN(durationPkwt12) ? durationPkwt12 : 0;

  const totalDurationPkwtMinusRemainingPkwtActive = totalDurationPkwt - (remainingDurationPkwtActive / changeDurationMonth)

  const totalDurationYear = Math.floor(totalDurationPkwtMinusRemainingPkwtActive / 12)
  const totalDurationMonth = Math.floor(totalDurationPkwtMinusRemainingPkwtActive % 12)

  const formatTotalDuration = totalDurationYear === 0 ? `${totalDurationMonth} Bulan` : `${totalDurationYear} Tahun , ${totalDurationMonth} Bulan`

  const employeeContract = ikatan_kerja === 'KONTRAK'

  // bu gita & bu ninik
  const employeeSpecial = nik === 1216229 || nik === 1216231;

  const totalDurationPkwtNonContract = (new Date() - new Date(awal_pkwt_1))
  const totalDurationYearNonContract = Math.floor(totalDurationPkwtNonContract / (1000 * 60 * 60 * 24 * 365))
  const totalDurationMonthNonContract = Math.floor((totalDurationPkwtNonContract % (1000 * 60 * 60 * 24 * 365)) / changeDurationMonth)

  const formatTotalDurationNonContract = totalDurationYearNonContract === 0 ? `${totalDurationMonthNonContract} Bulan` : `${totalDurationYearNonContract} Tahun , ${totalDurationMonthNonContract} Bulan`

  const employeeNonAktive = status === 'NON AKTIF'

  return (
    <>
      <div className='container mt-3 border rounded animate__animated animate__fadeInDown'>
        <h1 className='title-h1 text-center'>MASA KERJA</h1>
        <Tabs defaultActiveKey="active_contract" >
          <Tab eventKey="active_contract" title="KONTRAK AKTIF">
            {!employeeContract ? (
              <div className='pkwt d-flex flex-column justify-content-center align-items-center'>
                <div className='d-flex'>
                  <div className='d-flex flex-column justify-content-center p-5 border-end border-2 border-black'>
                    <div className='no-pkwt'>Karyawan {ikatan_kerja}</div>
                  </div>
                  <div className='p-5'>
                    <div className='no-pkwt'>TOTAL MASA KERJA</div>
                    <div className='fs-2 text-center mt-3'>{formatTotalDurationNonContract}</div>
                  </div>
                </div>
                <div className='fs-3 w-100 mt-5 rounded'><span>Riwayat Jabatan : </span>{riwayat_jabatan}</div>
              </div>
            ) : employeeSpecial ? (
              <div className='pkwt d-flex flex-column justify-content-center align-items-center'>
                <div className='d-flex'>
                  <div className='d-flex flex-column justify-content-center p-5 border-end border-2 border-black'>
                    <div className='no-pkwt'>Karyawan Khusus</div>
                  </div>
                  <div className='p-5'>
                    <div className='no-pkwt'>TOTAL MASA KERJA</div>
                    <div className='fs-2 text-center mt-3'>{formatTotalDurationNonContract}</div>
                  </div>
                </div>
                <div className='fs-3 w-100 mt-5 rounded'><span>Riwayat Jabatan : </span>{riwayat_jabatan}</div>
              </div>
            ) : employeeNonAktive ? (
              <div className='pkwt'>
                <div className='no-pkwt text-danger fs-1 text-decoration-none'>Karyawan NON AKTIF</div>
              </div>
            ) : (
              <div className='pkwt d-flex flex-column justify-content-center align-items-center'>
                <div className='d-flex'>
                  <div className='d-flex flex-column p-5 border-end border-2 border-black'>
                    <div className='no-pkwt'>No. PKWT : {selectionNoPkwt}</div>
                    <div className='date-pkwt'><i className="bi bi-calendar me-2"></i>{selectionDateStartPkwt} - {selectionDateEndPkwt}</div>
                    <div className='duration-pkwt'>Durasi : {Math.round(durationPkwtActive)} bulan<span className='text-danger ms-2'>(Sisa : {formatTimeRemaining(remainingDurationPkwtActive)})</span></div>
                    <div className='position-pkwt'>Jabatan : {selectionPositionPkwt}</div>
                  </div>
                  <div className='p-5'>
                    <div className='no-pkwt'>TOTAL MASA KERJA</div>
                    <div className='fs-2 text-center mt-3'>{formatTotalDuration}</div>
                  </div>
                </div>
                <div className='fs-3 w-100 mt-4 rounded'><span>Riwayat Jabatan : </span>{riwayat_jabatan}</div>
              </div>
            )}
          </Tab>
          {!isValueEmpty(no_pkwt_1) && (
            <Tab eventKey="contract_1" title="KONTRAK-1">
              <div className='pkwt'>
                <div className='no-pkwt'>No. PKWT : {no_pkwt_1}</div>
                <div className='date-pkwt'><i className="bi bi-calendar me-2"></i>{dateStartPkwt1} - {dateEndPkwt1}</div>
                <div className='duration-pkwt'>Durasi : {Math.round(durationPkwt1)} bulan</div>
                {!isValueEmpty(no_pPkwt_1) && (
                  <>
                    <div className='no-pkwt'>No. P-PKWT : {no_pPkwt_1}</div>
                    <div className='date-pkwt'><i className="bi bi-calendar me-2"></i>{dateEndPpkwt1}</div>
                    <div className='duration-pkwt'>Durasi : {Math.round(durationPpkwt1)} bulan</div>
                  </>
                )}
                <div className='position-pkwt'>Jabatan : {jabatan_pkwt_1}</div>
                {!isValueEmpty(catatan_pkwt_1) && (
                  <div className='notes-pkwt'>* {catatan_pkwt_1}</div>
                )}
              </div>
            </Tab>
          )}
          {!isValueEmpty(no_pkwt_2) && (
            <Tab eventKey="contract_2" title="KONTRAK-2">
              <div className='pkwt'>
                <div className='no-pkwt'>No. PKWT : {no_pkwt_2}</div>
                <div className='date-pkwt'><i className="bi bi-calendar me-2"></i>{dateStartPkwt2} - {dateEndPkwt2}</div>
                <div className='duration-pkwt'>Durasi : {Math.round(durationPkwt2)} bulan</div>
                {!isValueEmpty(no_pPkwt_2) && (
                  <>
                    <div className='no-pkwt'>No. P-PKWT : {no_pPkwt_2}</div>
                    <div className='date-pkwt'><i className="bi bi-calendar me-2"></i>{dateEndPpkwt2}</div>
                    <div className='duration-pkwt'>Durasi : {Math.round(durationPpkwt2)} bulan</div>
                  </>
                )}
                <div className='position-pkwt'>Jabatan : {jabatan_pkwt_2}</div>
                {!isValueEmpty(catatan_pkwt_2) && (
                  <div className='notes-pkwt'>* {catatan_pkwt_2}</div>
                )}
              </div>
            </Tab>
          )}
          {!isValueEmpty(no_pkwt_3) && (
            <Tab eventKey="contract_3" title="KONTRAK-3">
              <div className='pkwt'>
                <div className='no-pkwt'>No. PKWT : {no_pkwt_3}</div>
                <div className='date-pkwt'><i className="bi bi-calendar me-2"></i>{dateStartPkwt3} - {dateEndPkwt3}</div>
                <div className='duration-pkwt'>Durasi : {Math.round(durationPkwt3)} bulan</div>
                {!isValueEmpty(no_pPkwt_3) && (
                  <>
                    <div className='no-pkwt'>No. P-PKWT : {no_pPkwt_3}</div>
                    <div className='date-pkwt'><i className="bi bi-calendar me-2"></i>{dateEndPpkwt3}</div>
                    <div className='duration-pkwt'>Durasi : {Math.round(durationPpkwt3)} bulan</div>
                  </>
                )}
                <div className='position-pkwt'>Jabatan : {jabatan_pkwt_3}</div>
                {!isValueEmpty(catatan_pkwt_3) && (
                  <div className='notes-pkwt'>* {catatan_pkwt_3}</div>
                )}
              </div>
            </Tab>
          )}
          {!isValueEmpty(no_pkwt_4) && (
            <Tab eventKey="contract_4" title="KONTRAK-4">
              <div className='pkwt'>
                <div className='no-pkwt'>No. PKWT : {no_pkwt_4}</div>
                <div className='date-pkwt'><i className="bi bi-calendar me-2"></i>{dateStartPkwt4} - {dateEndPkwt4}</div>
                <div className='duration-pkwt'>Durasi : {Math.round(durationPkwt4)} bulan</div>
                <div className='position-pkwt'>Jabatan : {jabatan_pkwt_4}</div>
                {!isValueEmpty(catatan_pkwt_4) && (
                  <div className='notes-pkwt'>* {catatan_pkwt_4}</div>
                )}
              </div>
            </Tab>
          )}
          {!isValueEmpty(no_pkwt_5) && (
            <Tab eventKey="contract_5" title="KONTRAK-5">
              <div className='pkwt'>
                <div className='no-pkwt'>No. PKWT : {no_pkwt_5}</div>
                <div className='date-pkwt'><i className="bi bi-calendar me-2"></i>{dateStartPkwt5} - {dateEndPkwt5}</div>
                <div className='duration-pkwt'>Durasi : {Math.round(durationPkwt5)} bulan</div>
                <div className='position-pkwt'>Jabatan : {jabatan_pkwt_5}</div>
                {!isValueEmpty(catatan_pkwt_5) && (
                  <div className='notes-pkwt'>* {catatan_pkwt_5}</div>
                )}
              </div>
            </Tab>
          )}
          {!isValueEmpty(no_pkwt_6) && (
            <Tab eventKey="contract_6" title="KONTRAK-6">
              <div className='pkwt'>
                <div className='no-pkwt'>No. PKWT : {no_pkwt_6}</div>
                <div className='date-pkwt'><i className="bi bi-calendar me-2"></i>{dateStartPkwt6} - {dateEndPkwt6}</div>
                <div className='duration-pkwt'>Durasi : {Math.round(durationPkwt6)} bulan</div>
                <div className='position-pkwt'>Jabatan : {jabatan_pkwt_6}</div>
                {!isValueEmpty(catatan_pkwt_6) && (
                  <div className='notes-pkwt'>* {catatan_pkwt_6}</div>
                )}
              </div>
            </Tab>
          )}
          {!isValueEmpty(no_pkwt_7) && (
            <Tab eventKey="contract_7" title="KONTRAK-7">
              <div className='pkwt'>
                <div className='no-pkwt'>No. PKWT : {no_pkwt_7}</div>
                <div className='date-pkwt'><i className="bi bi-calendar me-2"></i>{dateStartPkwt7} - {dateEndPkwt7}</div>
                <div className='duration-pkwt'>Durasi : {Math.round(durationPkwt7)} bulan</div>
                <div className='position-pkwt'>Jabatan : {jabatan_pkwt_7}</div>
                {!isValueEmpty(catatan_pkwt_7) && (
                  <div className='notes-pkwt'>* {catatan_pkwt_7}</div>
                )}
              </div>
            </Tab>
          )}
          {!isValueEmpty(no_pkwt_8) && (
            <Tab eventKey="contract_8" title="KONTRAK-8">
              <div className='pkwt'>
                <div className='no-pkwt'>No. PKWT : {no_pkwt_8}</div>
                <div className='date-pkwt'><i className="bi bi-calendar me-2"></i>{dateStartPkwt8} - {dateEndPkwt8}</div>
                <div className='duration-pkwt'>Durasi : {Math.round(durationPkwt8)} bulan</div>
                <div className='position-pkwt'>Jabatan : {jabatan_pkwt_8}</div>
                {!isValueEmpty(catatan_pkwt_8) && (
                  <div className='notes-pkwt'>* {catatan_pkwt_8}</div>
                )}
              </div>
            </Tab>
          )}
          {!isValueEmpty(no_pkwt_9) && (
            <Tab eventKey="contract_9" title="KONTRAK-9">
              <div className='pkwt'>
                <div className='no-pkwt'>No. PKWT : {no_pkwt_9}</div>
                <div className='date-pkwt'><i className="bi bi-calendar me-2"></i>{dateStartPkwt9} - {dateEndPkwt9}</div>
                <div className='duration-pkwt'>Durasi : {Math.round(durationPkwt9)} bulan</div>
                <div className='position-pkwt'>Jabatan : {jabatan_pkwt_9}</div>
                {!isValueEmpty(catatan_pkwt_9) && (
                  <div className='notes-pkwt'>* {catatan_pkwt_9}</div>
                )}
              </div>
            </Tab>
          )}
          {!isValueEmpty(no_pkwt_10) && (
            <Tab eventKey="contract_10" title="KONTRAK-10">
              <div className='pkwt'>
                <div className='no-pkwt'>No. PKWT : {no_pkwt_10}</div>
                <div className='date-pkwt'><i className="bi bi-calendar me-2"></i>{dateStartPkwt10} - {dateEndPkwt10}</div>
                <div className='duration-pkwt'>Durasi : {Math.round(durationPkwt10)} bulan</div>
                <div className='position-pkwt'>Jabatan : {jabatan_pkwt_10}</div>
                {!isValueEmpty(catatan_pkwt_10) && (
                  <div className='notes-pkwt'>* {catatan_pkwt_10}</div>
                )}
              </div>
            </Tab>
          )}
          {!isValueEmpty(no_pkwt_11) && (
            <Tab eventKey="contract_11" title="KONTRAK-11">
              <div className='pkwt'>
                <div className='no-pkwt'>No. PKWT : {no_pkwt_11}</div>
                <div className='date-pkwt'><i className="bi bi-calendar me-2"></i>{dateStartPkwt11} - {dateEndPkwt11}</div>
                <div className='duration-pkwt'>Durasi : {Math.round(durationPkwt11)} bulan</div>
                <div className='position-pkwt'>Jabatan : {jabatan_pkwt_11}</div>
                {!isValueEmpty(catatan_pkwt_11) && (
                  <div className='notes-pkwt'>* {catatan_pkwt_11}</div>
                )}
              </div>
            </Tab>
          )}
          {!isValueEmpty(no_pkwt_12) && (
            <Tab eventKey="contract_12" title="KONTRAK-12">
              <div className='pkwt'>
                <div className='no-pkwt'>No. PKWT : {no_pkwt_12}</div>
                <div className='date-pkwt'><i className="bi bi-calendar me-2"></i>{dateStartPkwt12} - {dateEndPkwt12}</div>
                <div className='duration-pkwt'>Durasi : {Math.round(durationPkwt12)} bulan</div>
                <div className='position-pkwt'>Jabatan : {jabatan_pkwt_12}</div>
                {!isValueEmpty(catatan_pkwt_12) && (
                  <div className='notes-pkwt'>* {catatan_pkwt_12}</div>
                )}
              </div>
            </Tab>
          )}
        </Tabs>
      </div>
    </>
  )
}

export default PKWT
