import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import HOK from './Absensi/HOK'
import JOK from './Absensi/JOK'
import Kpi from './KPI'
import DailyReport from './DR'
import Sick from './Absensi/Sick'
import Late from './Absensi/Late'
import Absent from './Absensi/Absent'
import Permission from './Absensi/Permission'
import GoHome from './Absensi/Go Home'
import Evaluation from './Evaluation'
import { Tab, Tabs } from 'react-bootstrap'
import { months } from '../Components/Utility'

const Performance = () => {

  const { nik } = useParams('')
  const [performanceByNikAndYear, setPerformanceByNikAndYear] = useState({})
  const [yearList, setYearList] = useState([])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL}/performance/${nik}`)
      .then((res) => {
        const data = res.data.dataPerformanceByNik

        const performanceByYear = data.reduce((acc, item) => {
          const year = new Date(item.periode).getFullYear()
          if (!acc[year]) {
            acc[year] = []
          }
          acc[year].push(item)
          return acc
        }, {})

        setPerformanceByNikAndYear(performanceByYear)
        setYearList(Object.keys(performanceByYear).sort((a, b) => b - a))
      })
      .catch((err) => console.log(err))
  }, [nik])

  const filterDataPerformanceByNik = (category, month, year) => {
    const dataForYear = performanceByNikAndYear[year] || [];
    return dataForYear.filter(item => {
      const matchCategory = category ? item.category.toLowerCase() === category.toLowerCase() : true;
      const matchMonth = month ? new Date(item.periode).getMonth() === months.indexOf(month) : true;
      return matchCategory && matchMonth;
    });
  };

  return (
    <>
      <div className='container mt-3 border rounded animate__animated animate__fadeInDown'>
        <div className='title-h1 fs-1 text-center'>Penilaian</div>
        <Tabs defaultActiveKey={yearList[0]}>
          {yearList.map((year) => (
            <Tab eventKey={year} title={year} key={year}>
              <table className='table table-bordered border-dark mt-1 mb-4'>
                <thead className='table-warning table-bordered border-dark'>
                  <tr className='text-center fs-5'>
                    <th colSpan={2}>Category</th>
                    <th>Jan</th>
                    <th>Feb</th>
                    <th>Mar</th>
                    <th>Apr</th>
                    <th>Mei</th>
                    <th>Jun</th>
                    <th>Jul</th>
                    <th>Agust</th>
                    <th>Sept</th>
                    <th>Okt</th>
                    <th>Nov</th>
                    <th>Des</th>
                    <th>Total/Average</th>
                  </tr>
                </thead>
                <HOK year={year} filterDataPerformanceByNik={filterDataPerformanceByNik} />
                <JOK year={year} filterDataPerformanceByNik={filterDataPerformanceByNik} />
                <Late year={year} filterDataPerformanceByNik={filterDataPerformanceByNik} />
                <Absent year={year} filterDataPerformanceByNik={filterDataPerformanceByNik} />
                <Permission year={year} filterDataPerformanceByNik={filterDataPerformanceByNik} />
                <Sick year={year} filterDataPerformanceByNik={filterDataPerformanceByNik} />
                <GoHome year={year} filterDataPerformanceByNik={filterDataPerformanceByNik} />
                <Kpi year={year} filterDataPerformanceByNik={filterDataPerformanceByNik} />
                <DailyReport year={year} filterDataPerformanceByNik={filterDataPerformanceByNik} />
                <Evaluation year={year} filterDataPerformanceByNik={filterDataPerformanceByNik} />
              </table>
            </Tab>
          ))}
        </Tabs>
      </div>
    </>
  )
}

export default Performance