import React, { useEffect, useState } from 'react'
import axios from 'axios'
import logoAE from '../../Assets/Logo/Aditama.png'
import logoBK from '../../Assets/Logo/BK.png'
import logoSN from '../../Assets/Logo/SINET.png'
import NavigationBar from '../../Components/Utility/Navbar'
import PKWT from '../../PKWT'
import Performance from '../../Performance'
import Vacation from '../../Vacation'
import Family from '../../Family'
import WarningLetter from '../../WarningLetter'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { countAge, dateFormated, generationCategory, Loaders } from '../../Components/Utility'
import { Tooltip } from 'react-tooltip'
import Training from '../../Training'
import DrivingLicense from '../../DrivingLicense'
import Prestasi from '../../Prestasi'
import Notes from '../../Notes'
import { jwtDecode } from 'jwt-decode';

const DetailEmployee = () => {

  const [dataDetailEmployees, setDataDetailEmployee] = useState([])
  const [dataVacationByNik, setDataVacationByNik] = useState([])
  const [dataFamilyByNik, setDataFamilyByNik] = useState([])
  const [dataWarningLetterByNik, setDataWarningLetterByNik] = useState([])
  const [dataPrestasiByNik, setDataPrestasiByNik] = useState([])
  const [dataTrainingByNik, setDataTrainingByNik] = useState([])
  const [dataDrivingLicenseByNik, setDataDrivingLicenseByNik] = useState([])
  const [dataNotesByNik, setDataNotesByNik] = useState([])
  const [isAdmin, setIsAdmin] = useState('')
  const [username, setUsername] = useState('')
  const [master, setMaster] = useState('')
  const { nik } = useParams('')
  const navigate = useNavigate('')
  const location = useLocation()
  const dataEmployees = location.state.dataEmployees;
  const [loading, setLoading] = useState(true)
  const previousPath = location.state?.from || '/dataEmployee';

  useEffect(() => {
    Promise.all([
      axios.get(`${process.env.REACT_APP_URL}/employees/${nik}`)
        .then((result) => {
          setDataDetailEmployee(result.data.dataEmployeeByNik)
        }),
      axios.get(`${process.env.REACT_APP_URL}/vacation/${nik}`)
        .then((res) => setDataVacationByNik(res.data.dataVacationByNik))
        .catch((err) => console.log('err vacation', err)),
      axios.get(`${process.env.REACT_APP_URL}/family/${nik}`)
        .then((res) => setDataFamilyByNik(res.data.dataFamilyByNik))
        .catch((err) => console.log('err family', err)),
      axios.get(`${process.env.REACT_APP_URL}/warningletters/${nik}`)
        .then((res) => setDataWarningLetterByNik(res.data.dataWarningLetterByNik))
        .catch((err) => console.log('err sp', err)),
      axios.get(`${process.env.REACT_APP_URL}/prestasi/${nik}`)
        .then((res) => setDataPrestasiByNik(res.data.dataPrestasiByNik))
        .catch((err) => console.log('err prestasi', err)),
      axios.get(`${process.env.REACT_APP_URL}/training/${nik}`)
        .then((res) => setDataTrainingByNik(res.data.dataTrainingByNik))
        .catch((err) => console.log('err training', err)),
      axios.get(`${process.env.REACT_APP_URL}/license/${nik}`)
        .then((res) => setDataDrivingLicenseByNik(res.data.dataDrivingLicenseByNik))
        .catch((err) => console.log('err license', err)),
      axios.get(`${process.env.REACT_APP_URL}/notes/${nik}`)
        .then((res) => setDataNotesByNik(res.data.dataNotesByNik))
        .catch((err) => console.log('err license', err)),
      axios.get(`${process.env.REACT_APP_URL}/users/token`, { withCredentials: true })
        .then((res) => {
          const decoded = jwtDecode(res.data.token)
          setUsername(decoded.username)
          setMaster(decoded.username === 'Aliansyah')
          setIsAdmin(decoded.status.toLowerCase() === 'admin')
        })
    ]).then(() => {
      setLoading(false)
    }).catch((err) => {
      console.log('Error fetching data:', err)
    })
  }, [nik])

  const { unit, nama_lengkap, alamat, domisili, jenis_kelamin, tempat_lahir, tanggal_lahir, no_hp, ktp, agama, pendidikan, status_pernikahan, status_pajak, status, jabatan, divisi, level, lokasi_kerja, ikatan_kerja, no_bpjs_kesehatan, no_bpjs_ketenagakerjaan, foto_karyawan } = dataDetailEmployees

  const placeOfBirth = dateFormated(tanggal_lahir)

  const companyLogos = {
    'ADITAMA ENERGI': logoAE,
    'BARA KUMALA': logoBK,
    'SINET': logoSN
  }

  const logo = companyLogos[unit] || '';
  const imageEmployee = `${process.env.REACT_APP_URL}/employees/${foto_karyawan}`

  const dataFamily = dataFamilyByNik.length === 0;
  const dataVacation = dataVacationByNik.length === 0;
  const dataWarningLetter = dataWarningLetterByNik.length === 0;
  const dataTraining = dataTrainingByNik.length === 0;
  const dataLicense = dataDrivingLicenseByNik.length === 0;
  const dataNotes = dataNotesByNik.length === 0;

  return (
    <div className='mb-3'>
      <NavigationBar logoUnit={logo} namaLengkap={nama_lengkap} nik={nik} />
      {loading ? (
        <Loaders />
      ) : (
        <>
          <i className="bi bi-arrow-left"
            onClick={() => navigate(previousPath, { state: { dataEmployees: dataEmployees, isAdmin: isAdmin, username: username, master: master } })}
          ></i>
          {isAdmin && (
            <i className="bi bi-plus-square"
              data-tooltip-id='input-tooltip' data-tooltip-content='input data karyawan'
              onClick={() => navigate('/input', { state: { dataDetailEmployees: dataDetailEmployees, dataDrivingLicenseByNik: dataDrivingLicenseByNik } })}
            ></i>
          )}
          <Tooltip id='input-tooltip' style={{ zIndex: "4" }} />
          {isAdmin && (
            <i className="bi bi-pencil-square" data-tooltip-id='update-employee-tooltip' data-tooltip-content="update data karyawan"
              onClick={() => navigate('/detailEmployee/updateDataEmployee', { state: { username: username, dataDetailEmployees: dataDetailEmployees, dataEmployees: dataEmployees, dataVacationByNik: dataVacationByNik, dataFamilyByNik: dataFamilyByNik, dataWarningLetterByNik: dataWarningLetterByNik, dataPrestasiByNik: dataPrestasiByNik, dataTrainingByNik: dataTrainingByNik, dataDrivingLicenseByNik: dataDrivingLicenseByNik, dataNotesByNik: dataNotesByNik } })}></i>
          )}
          <Tooltip id='update-employee-tooltip' style={{ zIndex: "4" }} />
          <div className='container mt-3 border rounded d-flex animate__animated animate__fadeInDown'>
            <div className='p-3 shadow shadow-sm bg-light rounded m-2 w-50'>
              <h1 className='title-h1'>Profile Karyawan</h1>
              <h5>Nama Lengkap : <span>{nama_lengkap}</span></h5>
              <h5>Jenis Kelamin : <span>{jenis_kelamin}</span></h5>
              <h5>Tempat & Tanggal Lahir : <span>{tempat_lahir} / {placeOfBirth}</span></h5>
              <h5>Umur : <span>{countAge(tanggal_lahir)} Tahun <span className='text-secondary me-1'>({generationCategory(tanggal_lahir)})</span></span></h5>
              <h5>No HandPhone : <span>{no_hp}</span></h5>
              <h5>No KTP : <span>{ktp}</span></h5>
              <h5>Agama : <span>{agama}</span></h5>
              <h5>Pendidikan : <span>{pendidikan}</span></h5>
              <h5>Status Pernikahan : <span>{status_pernikahan} / {status_pajak}</span></h5>
              <h5>Alamat : <span>{alamat}</span></h5>
              <h5>Domisili : <span>{domisili}</span></h5>
            </div>
            <div className='p-3 shadow shadow-sm bg-light rounded m-2 w-50'>
              <h1 className='title-h1'>ID Karyawan</h1>
              <h5>NIK : <span>{nik}</span></h5>
              <h5>Status : <span>{status}</span></h5>
              <h5 >Jabatan : <span>{jabatan}</span></h5>
              <h5>Divisi : <span>{divisi}</span></h5>
              <h5>Level : <span>{level}</span></h5>
              <img src={imageEmployee} alt='foto karyawan' className='foto-employee animate__animated animate__bounce' onClick={() => window.open(imageEmployee)} />
              <h5>Lokasi Kerja  : <span>{lokasi_kerja}</span></h5>
              <h5>Ikatan Kerja  : <span>{ikatan_kerja}</span></h5>
              <h5>No BPJS Kesehatan  : <span>{no_bpjs_kesehatan}</span></h5>
              <h5>No BPJS Ketenagakerjaan  : <span>{no_bpjs_ketenagakerjaan}</span></h5>
              <Prestasi dataPrestasiByNik={dataPrestasiByNik} />
            </div>
          </div>
          <PKWT dataDetailEmployees={dataDetailEmployees} />
          <Performance dataDetailEmployees={dataDetailEmployees} />
          {!dataVacation && <Vacation dataDetailEmployees={dataDetailEmployees} dataVacationByNik={dataVacationByNik} isAdmin={isAdmin} />}
          {!dataFamily && <Family dataDetailEmployees={dataDetailEmployees} dataFamilyByNik={dataFamilyByNik} />}
          {!dataLicense &&
            <DrivingLicense dataDetailEmployees={dataDetailEmployees} dataDrivingLicenseByNik={dataDrivingLicenseByNik} isAdmin={isAdmin} />
          }
          {!dataWarningLetter && <WarningLetter dataDetailEmployees={dataDetailEmployees} dataWarningLetterByNik={dataWarningLetterByNik} />}
          {!dataTraining && <Training dataDetailEmployees={dataDetailEmployees} dataTrainingByNik={dataTrainingByNik} />}
          {!dataNotes && <Notes dataDetailEmployees={dataDetailEmployees} dataNotesByNik={dataNotesByNik} />}
        </>
      )}
    </div>
  )
}

export default DetailEmployee